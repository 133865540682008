import FilmServiceClient from "../FilmServiceClient";
import TagDto from "./TagDto";

const getAll = async () => {

  let tags: TagDto[] | null = null

  try {
    const resp = await FilmServiceClient.get<TagDto[]>('tag/list')

    tags = resp.data
  } catch (e) { /* empty */ }

  return tags
}

const TagService = {
  getAll
}

export default TagService
