import {useCallback, useEffect, useState} from "react";
import Clip from "../../types/clip/Clip";
import ClipService from "../../services/clip/ClipService";
import {useHistory} from "react-router-dom";
import RouteConstants from "../../util/RouteConstants";
import ClipMapper from "../../types/clip/ClipMapper";
import {UploadFile} from "antd/lib/upload/interface";
import S3Service from "../../services/s3service/S3Service";

const useClipsModel = () => {

  const [clips, setClips] = useState<Clip[]>([])
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [selectedClipIds, setSelectedClipIds] = useState<string[]>([])
  const history = useHistory()
  const [activeView, setActiveView] = useState<'grid' | 'table'>('grid')
  const [searchText, setSearchText] = useState<string>()
  const [selectedTags, setSelectedTags] = useState<string[]>()

  const [uploadProgress, setUploadProgress] = useState<{[key: string]: number}>({})

  const fetchClips = async () => {
    const newClips = await ClipService.readClips()

    if (newClips) {
      setClips(newClips.map(c => ClipMapper.asClip(c)))
    }
  }

  useEffect(() => {
    fetchClips()
  }, [])

  const handleCreateClip = async () => {
    const createdClip = await ClipService.createClip({
      annotations: [],
      narrations: [],
      endSecond: null,
      sourceBucketObject: null,
      startSecond: null,
      title: "",
      id: null,
      creatorId: null,
      bucketObject: null,
      tags: []
    })

    if (createdClip && createdClip.id) {
      history.push(RouteConstants.CLIP.replace(':clipId', createdClip.id))
    }
  }

  const handleUploadProgress = useCallback((filename: string) => (progressEvent: ProgressEvent) => {
    setUploadProgress(prevState => {
      prevState[filename] = progressEvent.loaded / progressEvent.total * 100
      return {...prevState}
    })
  }, [setUploadProgress])

  const handleUpload = async (file: UploadFile, FileList: UploadFile[]) => {

    if (FileList) {
      FileList.forEach(f => {
        ClipService.createClip({
          annotations: [],
          narrations: [],
          endSecond: null,
          sourceBucketObject: null,
          startSecond: null,
          title: f.fileName || "",
          id: null,
          creatorId: null,
          bucketObject: null,
          tags: []
        }).then((clipDto) => {
          if (!clipDto || !clipDto.sourceBucketObject) {
            return
          }

          const blob = file as unknown as Blob;

          S3Service.putBucketObject(clipDto.sourceBucketObject.putLink, blob, 'video/mp4', handleUploadProgress(f.name))
        })
      })
      return
    }

    if (file) {
      ClipService.createClip({
        annotations: [],
        narrations: [],
        endSecond: null,
        sourceBucketObject: null,
        startSecond: null,
        title: file.fileName || "",
        id: null,
        creatorId: null,
        bucketObject: null,
        tags: []
      }).then((clipDto) => {
        if (!clipDto || !clipDto.sourceBucketObject) {
          return
        }

        const blob = file as unknown as Blob;

        S3Service.putBucketObject(clipDto.sourceBucketObject.putLink, blob, 'video/mp4')
      })
    }
  }

  const handleClickClipCard = (id: string) => () => {
    if (!isEditing) {
      history.push(RouteConstants.CLIP.replace(
        ':clipId',
        id
      ))
    } else {
      if (!selectedClipIds.find(c => c === id)) {
        setSelectedClipIds(selectedClipIds.concat(id))
      } else {
        setSelectedClipIds(selectedClipIds.filter(c => c !== id)
                                          .concat())
      }
    }
  }

  const handleClickEdit = () => {
    setIsEditing(true)
  }

  const handleClickCancel = () => {
    setIsEditing(false)
    setSelectedClipIds([])
  }

  const handleClickDelete = async () => {

    if (selectedClipIds.length < 1) {
      return
    }

    const successful = await ClipService.deleteClips(selectedClipIds)

    if (successful) {
      fetchClips()
      handleClickCancel()
    }
  }

  const handleActiveViewChange = (value: 'grid' | 'table') => {
    setActiveView(value)
  }

  const handleFormValuesChange = (values: { search: string, tags: string[] }) => {
    setSearchText(values.search)
    setSelectedTags(values.tags)
  }

  const filteredClips = clips
    .filter(c => c.title.includes(searchText || ''))
    .filter(c => selectedTags && selectedTags.length > 0 ? selectedTags?.map(t => !!c.tags.find(ct => ct.name === t)).every(v => v) : true)

  return {
    clips: filteredClips,
    handleCreateClip,
    handleClickClipCard,
    handleClickEdit,
    handleClickDelete,
    handleClickCancel,
    isEditing,
    selectedClipIds,
    activeView,
    handleActiveViewChange,
    handleFormValuesChange,
    handleUpload,
    uploadProgress
  }
}

export default useClipsModel;
