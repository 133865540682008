import React from "react";
import {Button} from "antd";
import GrabVerticalOutlined from "../../../../icons/GrabVerticalOutlined/GrabVerticalOutlined";

interface Props {
  left: number | string
  width: number | string
  onMouseEnter: (handle: 'start' | 'end') => void
  onMouseLeave: (handle: 'start' | 'end') => void
  backgroundColor: string
  stretchable?: boolean
  draggable?: boolean
  className?: string
  style?: React.CSSProperties
  sliderStyle?: React.CSSProperties
}

function TimelineSlider({left, width, onMouseEnter, onMouseLeave, children, backgroundColor, stretchable, draggable, className, style, sliderStyle}: React.PropsWithChildren<Props>) {

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: left,
        width: width,
        height: '100%',
        backgroundColor: backgroundColor,
        borderRadius: 8,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        zIndex: 25,
        ...style
      }}
      className={className}
    >
      {
        (stretchable || draggable) &&
        <Button
          className="hoverable draggable"
          icon={<GrabVerticalOutlined
            style={{
              fontSize: 24,
              display: 'flex',
              alignItems: "center",
              justifyContent: 'center',
              height: '100%'
            }}
          />}
          type="text"
          style={{
            height: '100%',
            borderRadius: 8,
            width: 24
          }}
          onMouseEnter={() => onMouseEnter("start")}
          onMouseLeave={() => onMouseLeave("start")}
        />
      }
      <div
        className="slider-content"
        style={{
          display: "flex",
          alignItems: "center",
          ...sliderStyle
        }}
      >
        {children}
      </div>
      {
        stretchable &&
        <Button
          className="hoverable draggable"
          icon={<GrabVerticalOutlined
            style={{
              fontSize: 24,
              display: 'flex',
              alignItems: "center",
              justifyContent: 'center',
              height: '100%'
            }}
          />}
          type="text"
          style={{
            height: '100%',
            borderRadius: 8,
            width: 24
          }}
          onMouseEnter={() => onMouseEnter("end")}
          onMouseLeave={() => onMouseLeave("end")}
        />
      }
    </div>
  )
}

export default TimelineSlider;
