import {Space} from "antd";
import React, {MutableRefObject, PropsWithChildren, useContext, useRef, useState} from "react";
import TimelineSegment, {Segment} from "./TimelineSegment";
import Annotation from "../../../types/annotation/Annotation";
import Narration from "../../../types/narration/Narration";
import NarrationTimeline from "./NarrationTimeline/NarrationTimeline";
import AnnotationTimeline from "./AnnotationTimeline/AnnotationTimeline";
import Trimmer from "./Trimmer/Trimmer";
import {ClipEditorAction} from "../../../views/clip/useClipEditor";
import {calculateElapsedPauseSeconds} from "../FilmVideoPlayer";
import TimeMarker from "./TimeMarker/TimeMarker";
import {UserPreferencesContext} from "../../../contexts/userpreferences/UserPreferencesContext";

interface Props {
  currentTime: number
  videoLength: number
  onCurrentTimeChange: (time: number) => void
  url: string
  annotations: Annotation[]
  narrations: Narration[]
  trimRange: number[] | null
  onIsDraggingChange: (isDragging: boolean) => void
  clipEditorDispatch: React.Dispatch<ClipEditorAction>
  clipId: string
  audioPlayerMapRef: MutableRefObject<Map<string, MutableRefObject<HTMLAudioElement | null>>>
}

function MediaSlider({
  currentTime,
  videoLength,
  onCurrentTimeChange,
  url,
  annotations,
  narrations,
  trimRange,
  onIsDraggingChange,
  clipEditorDispatch,
  clipId,
  audioPlayerMapRef
}: PropsWithChildren<Props>) {

  const containerRef = useRef<HTMLDivElement>(null)
  const [markerPosition, setMarkerPosition] = useState<number>(0)
  const mouseDown = useRef<boolean>(false)
  const isHoveringAnnotationOrNarration = useRef<boolean>()

  const {userPreferences} = useContext(UserPreferencesContext)

  const updateCurrentTime = (value: number) => {

    setMarkerPosition(value)

    onCurrentTimeChange(value)
  }

  const handleTrimRangeChange = (valueChanged: "start" | "end") => (value: number[]) => {
    if (valueChanged === "start") {
      setMarkerPosition(value[0])
      onCurrentTimeChange(value[0])
    } else {
      setMarkerPosition(value[1])
      onCurrentTimeChange(value[1])
    }

    clipEditorDispatch({type: "UPDATE_TRIM_RANGE", trimRange: value})
  }

  const handleMouseDown = (e: React.MouseEvent) => {
    if (isHoveringAnnotationOrNarration.current) {
      return
    }

    mouseDown.current = true
    onIsDraggingChange(true)

    const mousePosition = e.pageX - e.currentTarget.getBoundingClientRect().x - 12

    const value = mousePosition * videoLength / (e.currentTarget.scrollWidth - 24)

    updateCurrentTime(value)
  }

  const handleMouseUp = () => {
    mouseDown.current = false
    onIsDraggingChange(false)
  }

  const handleMouseMove = (e:  React.MouseEvent) => {

    if (mouseDown.current) {

      const mousePosition = e.pageX - e.currentTarget.getBoundingClientRect().x - 12

      const value = mousePosition * videoLength / (e.currentTarget.scrollWidth - 24)

      updateCurrentTime(value)
    }
  }

  const handleIsHoveringAnnotationChange = (value: boolean) => {
    isHoveringAnnotationOrNarration.current = value
  }

  const annotationSegments = annotations
    .sort((a, b) => a.startSecond - b.startSecond)
    .map(layer => {
      return {
        start: layer.startSecond,
        end: layer.endSecond,
        playerStart: layer.startSecond - calculateElapsedPauseSeconds(layer.startSecond, annotations),
        playerEnd: layer.endSecond - calculateElapsedPauseSeconds(layer.endSecond, annotations),
        layer: layer
      } as Segment
    })

  const middleSegments: Segment[] = [];

  for (let i = 0; i < annotationSegments.length - 1; i++) {
    middleSegments.push({
      start: annotationSegments[i].end,
      end: annotationSegments[i + 1].start,
      playerStart: annotationSegments[i].end - calculateElapsedPauseSeconds(annotationSegments[i].end, annotations),
      playerEnd: annotationSegments[i + 1].start - calculateElapsedPauseSeconds(annotationSegments[i + 1].start,
        annotations
      ),
    })
  }

  const segments = [
    {
      start: 0,
      end: annotations && annotations.length > 0 ? annotations.sort((a,
        b
      ) => a.startSecond - b.startSecond)[0].startSecond : videoLength,
      playerStart: 0,
      playerEnd: annotations && annotations.length > 0 ? annotations.sort((a,
        b
      ) => a.startSecond - b.startSecond)[0].startSecond : videoLength
    }, ...annotationSegments
      .concat(middleSegments)
      .sort((a, b) => b.start - a.start), ...(annotations.length > 0 ? [
      {
        start: annotations.sort((a, b) => a.startSecond - b.startSecond)[annotations.length - 1].endSecond,
        end: videoLength + (annotations
          .map(layer => layer.endSecond - layer.startSecond)
          .reduce((curr, acc) => curr + acc, 0)),
        playerStart: annotations.sort((a, b) => a.startSecond - b.startSecond)[annotations.length - 1].endSecond -
          calculateElapsedPauseSeconds(
            annotations.sort((a, b) => b.startSecond - a.startSecond)[annotations.length - 1].endSecond,
            annotations
          ),
        playerEnd: videoLength
      }
    ] : [])
  ].sort((a, b) => a.start - b.start)
   .map(segment => <TimelineSegment
     key={segment.start}
     segment={segment}
     url={url}
     width={(segment.end - segment.start) * 80}
   />)

  return (
    <div
      draggable={false}
      style={{
        position: 'relative',
        overflow: 'scroll',
        height: "fit-content",
        width: "100%",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          height: "fit-content",
          overflow: "visible",
          width: "fit-content",
          display: "contents",
          paddingLeft: 24,
          paddingRight: 24
        }}
      >
        <div
          onMouseMove={handleMouseMove}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          style={{
            position: "relative",
            height: "fit-content",
            width: videoLength * 80 + 24 + "px",
            paddingLeft: 12,
            paddingRight: 12
          }}
        >
          <Space
            direction="vertical"
            style={{
              width: "100%"
            }}
          >
            <TimeMarker videoLength={videoLength}></TimeMarker>
            <NarrationTimeline narrations={narrations} videoLength={videoLength} markerPosition={markerPosition} onCurrentTimeChange={updateCurrentTime} clipEditorDispatch={clipEditorDispatch} onIsHoveringNarrationChange={handleIsHoveringAnnotationChange} clipId={clipId} audioPlayerMapRef={audioPlayerMapRef}/>
            <AnnotationTimeline annotations={annotations} clipEditorDispatch={clipEditorDispatch} videoLength={videoLength} onIsHoveringAnnotationChange={handleIsHoveringAnnotationChange} onCurrentTimeChange={updateCurrentTime} markerPosition={markerPosition}/>
            <Space
              size={0}
              style={{
                width: "100%",
                overflow: "hidden"
              }}
            >
              {segments}
            </Space>
          </Space>

          <div
            style={{
              position: "absolute",
              top: 0,
              left: 12,
              width: "calc(100% - 24px)",
              height: "100%",
              overflow: "visible",
            }}
          >
            <div
              ref={containerRef}
              style={{
                position: "relative",
                height: "100%",
                width: "100%",
                overflow: "visible",
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: `${currentTime / videoLength * 100}%`,
                  backgroundColor: userPreferences?.preferredTheme === "dark" ? 'rgba(255, 255, 255, 1)' : 'rgb(255,251,0)',
                  height: '100%',
                  width: 4,
                  zIndex: 30
                }}
              />
              <div
                className="hoverable draggable"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: `${markerPosition / videoLength * 100}%`,
                  backgroundColor: 'rgba(255, 255, 255, 0.4)',
                  height: '100%',
                  width: 4,
                  zIndex: 30
                }}
              />
            </div>
            {
              trimRange &&
                <Trimmer
                    trimRange={trimRange}
                    onTrimRangeChange={handleTrimRangeChange}
                    videoLength={videoLength}
                    onIsDraggingChange={onIsDraggingChange}
                />
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default MediaSlider;
