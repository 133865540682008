import {RGBColor} from "react-color";
import ColorPaletteDto from "../../services/userpreferences/ColorPaletteDto";

export default class ColorPalette {
  id?: string
  colors: RGBColor[]

  constructor(dto: ColorPaletteDto) {
    this.id = dto.id
    this.colors = dto.colors
  }

  asDto() {
    return {
      id: this.id,
      colors: this.colors
    } as ColorPaletteDto
  }
}
