import NarrationDto from "./NarrationDto";
import FilmServiceClient from "../FilmServiceClient";
import {message} from "antd";

type CreateNarrationRequest = {
  id: string,
  startSecond: number,
  endSecond: number
}

const createNarration = async (narration: CreateNarrationRequest, clipId: string) => {

  let result: NarrationDto | null = null

  try {
    const response = await FilmServiceClient.post<NarrationDto>(`/narration`, {narration, clipId})

    result = response.data
  } catch (err: unknown) {
    message.error((err as Error).message)
  }

  return result
}

const NarrationService = {
  createNarration
}

export default NarrationService
