import React, {useState} from "react";
import {Button, Card, Form, Input, Space, Typography} from "antd";
import {Link, useHistory, useLocation} from "react-router-dom";
import {LockOutlined, MailOutlined, RedoOutlined} from "@ant-design/icons";
import RouteConstants from "../../util/RouteConstants";
import AuthService from "../../services/auth/AuthService";

const {Text} = Typography;

function ResetPassword() {

  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(location.search)

  const token = params.get("token")

  const [requestedEmail, setRequestedEmail] = useState<string>()
  const handleFinishRequestResetPassword = async (values: { email: string }) => {

    const {email} = values

    const isSuccessful = await AuthService.requestResetPassword(email)

    if (isSuccessful) {
      setRequestedEmail(email)
    }
  }

  const handleClickResend = async () => {

    if (!requestedEmail) {
      return
    }

    await AuthService.requestResetPassword(requestedEmail)
  }

  const handleFinishResetPassword = async (values: { password: string, confirmPassword: string }) => {
    const {
      password,
      confirmPassword
    } = values

    if (!token) {
      return
    }

    const isSuccessful = await AuthService.resetPassword(
      token,
      password,
      confirmPassword
    )

    if (isSuccessful) {
      history.push(RouteConstants.CLIPS)
    }
  }

  return (
    <Card title="Reset Password">
      {
        !token && !requestedEmail &&
          <Form
              name="request-reset-password"
              onFinish={handleFinishRequestResetPassword}
              scrollToFirstError
              style={{minWidth: '400px'}}
          >
              <Form.Item
                  name="email"
                  rules={[{required: true, message: 'Please input your Email!'}, {type: 'email'}]}
              >
                  <Input
                      prefix={<MailOutlined className="site-form-item-icon"/>}
                      placeholder="Email"
                  />
              </Form.Item>
              <Form.Item>
                  <Space>
                      <Button type="primary" htmlType="submit" className="request-reset-password-button">
                          Continue
                      </Button>
                      or <Link to={RouteConstants.LOGIN}>Login</Link>
                  </Space>

              </Form.Item>
          </Form>

      }
      {
        !token && requestedEmail &&
          <Text>You should receive a password reset link at <mark style={{background: 'none', color: '#28AC8D'}}>{requestedEmail}</mark> shortly.<br/><br/>
              Click in the link in the email to proceed.<br/><br/>
              <Button icon={<RedoOutlined/>} onClick={handleClickResend}>Resend</Button>
          </Text>
      }
      {
        token &&

          <Form
              name="reset-password"
              onFinish={handleFinishResetPassword}
              scrollToFirstError
              style={{minWidth: '400px'}}
          >
              <Form.Item
                  name="password"
                  rules={[{required: true, message: 'Please input your Password!'}]}
              >
                  <Input
                      prefix={<LockOutlined className="site-form-item-icon"/>}
                      type="password"
                      placeholder="Password"
                  />
              </Form.Item>
              <Form.Item
                  name="confirmPassword"
                  dependencies={['password']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your password!',
                    },
                    ({getFieldValue}) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject('The two passwords that you entered do not match!');
                      },
                    }),
                  ]}
              >
                  <Input
                      prefix={<LockOutlined className="site-form-item-icon"/>}
                      type="password"
                      placeholder="Confirm Password"
                  />
              </Form.Item>
              <Form.Item>
                  <Space>
                      <Button type="primary" htmlType="submit" className="reset-password-form-button">
                          Reset Password
                      </Button>
                      or <Link to={RouteConstants.LOGIN}>Login</Link>
                  </Space>
              </Form.Item>
          </Form>
      }
    </Card>
  )
}

export default ResetPassword;
