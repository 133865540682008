import React, {useRef} from "react";
import {Circle, Ellipse} from "react-konva";
import {RGBColor} from "react-color";
import rgbColorToCssString from "../../../../util/RgbColorToCssString";
import {KonvaEventObject} from "konva/lib/Node";
import Konva from "konva";

interface Props {
  points: number[] // Should be 8 numbers on the axes of the ellipse, one axis, then the other
  color: RGBColor
  isSelected?: boolean
  onSelect?: () => void
  onShapeChange: (points: number[]) => void
  scale?: number
  strokeWidth?: number
}

function WhiteboardEllipse({
  isSelected,
  points,
  color,
  onShapeChange,
  onSelect,
  scale,
  strokeWidth
}: React.PropsWithChildren<Props>) {

  const previousPosition = useRef<number[]>()

  const alpha = Math.atan2(
    points[3] - points[1],
    points[2] - points[0]
  )

  const ellipseRef = useRef<Konva.Ellipse | null>(null)

  const ellipseCenter = [(points[2] + points[0]) / 2, (points[3] + points[1]) / 2]

  const b = Math.hypot(
    points[2] - points[0],
    points[3] - points[1]
  ) / 2

  const a = Math.hypot(
    points[6] - points[4],
    points[7] - points[5]
  ) / 2

  return (<>
    <Ellipse
      ref={ellipseRef}
      onClick={onSelect}
      onTap={onSelect}
      x={ellipseCenter[0]}
      y={ellipseCenter[1]}
      radiusX={b}
      radiusY={a}
      rotation={alpha * 180 / Math.PI}
      fill={rgbColorToCssString({
        ...color,
        a: .3
      })}
      stroke={rgbColorToCssString({
        ...color,
        a: 1
      })}
      strokeWidth={Math.max(
        (strokeWidth || 3) * (scale || 1),
        1
      )}
      draggable
      onDragStart={(e: KonvaEventObject<DragEvent>) => {
        const mouseEvent = e.evt as MouseEvent

        previousPosition.current = [mouseEvent.offsetX, mouseEvent.offsetY]
      }}
      onDragMove={(e: KonvaEventObject<DragEvent>) => {

        const mouseEvent = e.evt as MouseEvent
        onShapeChange && previousPosition.current && onShapeChange(points.map((p, i) => {
          if (i % 2 === 0) {
            return p + mouseEvent.offsetX - (previousPosition.current ? previousPosition.current[0] : 0)
          }

          if (i % 2 === 1) {
            return p + mouseEvent.offsetY - (previousPosition.current ? previousPosition.current[1] : 0)
          }

          return p
        }))

        previousPosition.current = [mouseEvent.offsetX, mouseEvent.offsetY]
      }}
    />
    {isSelected && (<>
      <Circle
        x={points[0]}
        y={points[1]}
        radius={5}
        fill="white"
        draggable
        onDragMove={(e: KonvaEventObject<DragEvent>) => {

          if (!onShapeChange) {
            return
          }

          const newAlpha = Math.atan2(
            points[3] - e.target.y(),
            points[2] - e.target.x()
          )

          const newCenter = [(e.target.x() + points[2]) / 2, (e.target.y() + points[3]) / 2]

          onShapeChange([
            e.target.x(),
            e.target.y(),
            points[2],
            points[3],
            newCenter[0] + Math.cos(newAlpha + Math.PI / 2) * a,
            newCenter[1] + Math.sin(newAlpha + Math.PI / 2) * a,
            newCenter[0] + Math.cos(newAlpha - Math.PI / 2) * a,
            newCenter[1] + Math.sin(newAlpha - Math.PI / 2) * a,

          ])
        }}
      />
      <Circle
        x={points[2]}
        y={points[3]}
        radius={5}
        fill="white"
        draggable
        onDragMove={(e: KonvaEventObject<DragEvent>) => {

          if (!onShapeChange) {
            return
          }

          const newAlpha = Math.atan2(
            e.target.y() - points[1],
            e.target.x() - points[0]
          )

          const newCenter = [(points[0] + e.target.x()) / 2, (points[1] + e.target.y()) / 2]

          onShapeChange([
            points[0],
            points[1],
            e.target.x(),
            e.target.y(),
            newCenter[0] + Math.cos(newAlpha + Math.PI / 2) * a,
            newCenter[1] + Math.sin(newAlpha + Math.PI / 2) * a,
            newCenter[0] + Math.cos(newAlpha - Math.PI / 2) * a,
            newCenter[1] + Math.sin(newAlpha - Math.PI / 2) * a,
          ])
        }}
      />
      <Circle
        x={points[4]}
        y={points[5]}
        radius={5}
        fill="white"
        draggable
        onDragMove={(e: KonvaEventObject<DragEvent>) => {

          if (!onShapeChange) {
            return
          }
          const newAlpha = Math.atan2(
            points[7] - e.target.y(),
            points[6] - e.target.x()
          ) + Math.PI

          const newCenter = [(e.target.x() + points[6]) / 2, (e.target.y() + points[7]) / 2]

          onShapeChange([
            newCenter[0] + Math.cos(newAlpha + Math.PI / 2) * b,
            newCenter[1] + Math.sin(newAlpha + Math.PI / 2) * b,
            newCenter[0] + Math.cos(newAlpha - Math.PI / 2) * b,
            newCenter[1] + Math.sin(newAlpha - Math.PI / 2) * b,
            e.target.x(),
            e.target.y(),
            points[6],
            points[7],

          ])
        }}
      />
      <Circle
        x={points[6]}
        y={points[7]}
        radius={5}
        fill="white"
        draggable
        onDragMove={(e: KonvaEventObject<DragEvent>) => {

          if (!onShapeChange) {
            return
          }

          const newAlpha = Math.atan2(
            e.target.y() - points[5],
            e.target.x() - points[4]
          ) + Math.PI

          const newCenter = [(points[4] + e.target.x()) / 2, (points[5] + e.target.y()) / 2]

          onShapeChange([
            newCenter[0] + Math.cos(newAlpha + Math.PI / 2) * b,
            newCenter[1] + Math.sin(newAlpha + Math.PI / 2) * b,
            newCenter[0] + Math.cos(newAlpha - Math.PI / 2) * b,
            newCenter[1] + Math.sin(newAlpha - Math.PI / 2) * b,
            points[4],
            points[5],
            e.target.x(),
            e.target.y(),

          ])
        }}
      />
    </>)}
  </>)
}

export default WhiteboardEllipse;
