import AnnotationDto from "./AnnotationDto";
import FilmServiceClient from "../FilmServiceClient";
import {message} from "antd";

const createAnnotation = async (annotation: AnnotationDto) => {

  let result: AnnotationDto | null = null;

  try {
    const response = await FilmServiceClient.post<AnnotationDto>(`/annotation`, {...annotation})

    result = response.data;
  } catch (err: unknown) {
    message.error((err as Error).message)
  }

  return result;
}

const AnnotationService = {
  createAnnotation
};

export default AnnotationService;
