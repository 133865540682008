import {message} from "antd";
import FilmServiceClient from "../FilmServiceClient";

const login = async (email: string, password: string): Promise<boolean> => {

  const requestBody = {
    email,
    password
  }

  try {
    await FilmServiceClient.post('/login', requestBody);
  } catch (err: unknown) {
    message.error((err as Error).message, 5)
    return false
  }

  return true;
}

const register = async (
  email: string,
  password: string,
  confirmPassword: string,
  referredBy: string | null
): Promise<boolean> => {

  const requestBody = {
    email,
    password,
    confirmPassword,
    referredBy
  }

  try {
    await FilmServiceClient.post('/register', requestBody);
  } catch (err: unknown) {
    message.error((err as Error).message, 5)
    return false
  }

  return true;
}

const requestResetPassword = async (email: string) => {

  try {
    await FilmServiceClient.post('/request-reset-password', {email})
  } catch (err: unknown) {
    message.error((err as Error).message, 5)
    return false
  }

  return true
}

const resetPassword = async (token: string, password: string, confirmPassword: string) => {

  const requestBody = {
    token,
    password,
    confirmPassword
  }

  try {
    await FilmServiceClient.post(
      '/reset-password',
      requestBody
    )
  } catch (err: unknown) {
    message.error((err as Error).message, 5)
    return false
  }

  return true
}

const AuthService = {
  login,
  register,
  requestResetPassword,
  resetPassword
}

export default AuthService;
