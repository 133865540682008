import React from "react";
import Icon from "@ant-design/icons";
import {ReactComponent as ArrowSolidOutlinedSvg} from "./ArrowSolidOutlined.svg"
import IconProps from "../IconProps";

interface Props extends IconProps {
}

function ArrowSolidOutlined(props: React.PropsWithChildren<Props>) {

  return (<Icon component={ArrowSolidOutlinedSvg} {...props} />)
}

export default ArrowSolidOutlined;
