import React, {useContext, useEffect, useState} from "react";
import {Dropdown, Menu} from "antd";
import {AudioInputContext} from "../../contexts/AudioInputContext/AudioInputContext";
import {AudioOutlined, CheckOutlined} from "@ant-design/icons";

function AudioInputPicker() {

  const {preferredDeviceId, setPreferredDeviceId} = useContext(AudioInputContext)

  const [streamOptions, setStreamOptions] = useState<MediaDeviceInfo[]>()

  const fetchStreamOptions = async () => {
    const opts = await navigator.mediaDevices.enumerateDevices()

    if (opts) {
      setStreamOptions(opts.filter(o => o.kind === "audioinput"))
    }
  }

  useEffect(() => {
    fetchStreamOptions()
  }, [])

  return (
    <Dropdown
      overlay={(
        <Menu
          onClick={(e) => e.item}
        >
          {
            streamOptions?.map((so, i) => {
              return (
                <Menu.Item
                  key={so.deviceId + i}
                  onClick={() => setPreferredDeviceId(so.deviceId)}
                  style={so.deviceId === preferredDeviceId ? {
                    color: "#28AC8D"
                  } : {}}
                >
                  {so.deviceId === preferredDeviceId && <CheckOutlined/>} {so.label}
                </Menu.Item>
              )
            })
          }
        </Menu>
      )}
    >
      <AudioOutlined onMouseEnter={fetchStreamOptions}></AudioOutlined>
    </Dropdown>
  )
}

export default AudioInputPicker;
