import {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {setUpInterceptors} from "../../services/FilmServiceClient";

function AxiosInterceptorInjector() {

  const history = useHistory()

  useEffect(() => {
    setUpInterceptors(history)
  }, [history])

  return null;
}

export default AxiosInterceptorInjector;
