import React, {useEffect, useState} from "react";

interface Props {

}

const AudioInputContext = React.createContext<{preferredDeviceId: string | null, setPreferredDeviceId: (value: string) => void}>({preferredDeviceId: null, setPreferredDeviceId: () => {}})

function AudioInputProvider({children}: React.PropsWithChildren<Props>) {

  const [preferredDeviceId, setPreferredDeviceId] = useState<string | null>(null)

  useEffect(() => {
    const preferredAudioDeviceId = localStorage.getItem("preferredAudioDeviceId")

    if (preferredAudioDeviceId) {
      setPreferredDeviceId(preferredAudioDeviceId)
    }
  }, [])

  useEffect(() => {
    if (preferredDeviceId) {
      localStorage.setItem("preferredAudioDeviceId", preferredDeviceId)
    }
  }, [preferredDeviceId])

  return (
    <AudioInputContext.Provider value={{preferredDeviceId, setPreferredDeviceId}}>
      {children}
    </AudioInputContext.Provider>
  )
}

export {AudioInputProvider, AudioInputContext};
