import Clip from "../../types/clip/Clip";
import {Reducer, useReducer} from "react";
import Annotation from "../../types/annotation/Annotation";
import Tag from "../../types/tag/Tag";
import Narration from "../../types/narration/Narration";

export type ClipEditorAction =
  { type: "LOAD", clip: Clip} |
  { type: "ADD_ANNOTATION", annotation: Annotation } |
  { type: "REMOVE_ANNOTATION", annotation: Annotation } |
  { type: "UPDATE_TRIM_RANGE", trimRange: number[] } |
  { type: "UPDATE_ANNOTATION_TIME_RANGE", annotation: Annotation } |
  { type: "UPDATE_ANNOTATION_SHAPES", annotation: Annotation } |
  { type: "ADD_NARRATION", narration: Narration } |
  { type: "UPDATE_NARRATION_TIME_RANGE", narration: Narration } |
  { type: "REMOVE_NARRATION", narration: Narration } |
  { type: "UPDATE_TITLE", value: string } |
  { type: "UPDATE_TAGS", tags: Tag[] } |
  { type: "UNDO" } |
  { type: "REDO" }

type ClipEditorState = {clip: Clip | null, undoStack: Clip[], redoStack: Clip[]}

function adjustSucceedingAnnotations(annotations: Annotation[], changedObjectStartSecond: number, changeInLength: number,) {

  return annotations.map(a => {
    if (a.startSecond > changedObjectStartSecond) {
      a.startSecond += changeInLength
      a.endSecond += changeInLength
    }

    return a
  })
}

function reducer(state: ClipEditorState | null, action: ClipEditorAction): ClipEditorState {

  if (action.type === "LOAD") {
    return {clip: action.clip, undoStack: [], redoStack: []}
  }

  if (!state?.clip) {
    return {clip: null, undoStack: [], redoStack: []}
  }
  const {clip} = state

  switch (action.type) {
    case "ADD_ANNOTATION": {
      const addedAnnotationLength = action.annotation.endSecond - action.annotation.startSecond
      return {
        ...state,
        clip: {
          ...state.clip,
          annotations: [
            ...adjustSucceedingAnnotations(
              clip.annotations,
              action.annotation.startSecond,
              addedAnnotationLength
            ), action.annotation
          ],
          endSecond: clip.endSecond ? clip.endSecond + addedAnnotationLength : null
        },
        undoStack: [...state.undoStack, state.clip]
      }
    }
    case "REMOVE_ANNOTATION": {
      const removedAnnotationLength = action.annotation.endSecond - action.annotation.startSecond
      return {
        ...state,
        clip: {
          ...state.clip,
          annotations: [
            ...adjustSucceedingAnnotations(
              clip.annotations.filter(a => a.id !== action.annotation.id),
              action.annotation.startSecond,
              -removedAnnotationLength
            ),
          ],
          endSecond: clip.endSecond ? clip.endSecond - removedAnnotationLength : null
        },
        undoStack: [...state.undoStack, state.clip]
      }
    }
    case "UPDATE_ANNOTATION_TIME_RANGE": {
      const changeInAnnotationLength = action.annotation.endSecond - action.annotation.startSecond
      return {
        ...state,
        clip: {
          ...state.clip,
          annotations: [
            ...adjustSucceedingAnnotations(
              clip.annotations.filter(a => a.id !== action.annotation.id),
              action.annotation.startSecond,
              changeInAnnotationLength
            ), action.annotation
          ],
          endSecond: clip.endSecond ? clip.endSecond + changeInAnnotationLength : null
        },
        undoStack: [...state.undoStack, state.clip]
      }
    }
    case "UPDATE_TRIM_RANGE":
      return {
        ...state,
        clip: { ...state.clip, startSecond: action.trimRange[0], endSecond: action.trimRange[1]},
        undoStack: [...state.undoStack, state.clip]
      }
    case "UPDATE_ANNOTATION_SHAPES":
      return {
        ...state,
        clip: { ...state.clip, annotations: [...clip.annotations.filter(a => a.id !== action.annotation.id), action.annotation]},
        undoStack: [...state.undoStack, state.clip]
      }
    case "ADD_NARRATION":
      return {
        ...state,
        clip: {
          ...state.clip,
          narrations: [
            ...state.clip.narrations,
            action.narration
          ]
        }
      }
    case "REMOVE_NARRATION":
      return {
        ...state,
        clip: {
          ...state.clip,
          narrations: clip.narrations.filter(a => a.id !== action.narration.id),
        },
        undoStack: [...state.undoStack, state.clip]

      }
    case "UPDATE_NARRATION_TIME_RANGE":
      return {
        ...state,
        clip: {
          ...state.clip,
          narrations: [...clip.narrations.filter(a => a.id !== action.narration.id), action.narration],
        },
        undoStack: [...state.undoStack, state.clip]

      }
    case "UPDATE_TITLE":
      return {
        ...state,
        clip: { ...state.clip, title: action.value}
      }
    case "UPDATE_TAGS":
      return {...state, clip: { ...state.clip, tags: action.tags}}
    case "UNDO": {

      const previousClipState = state.undoStack.pop()

      if (!previousClipState) {
        return state
      }

      return {
        clip: previousClipState,
        undoStack: state.undoStack,
        redoStack: [...state.redoStack, state.clip]
      }
    }
    case "REDO": {
      const previousClip = state.redoStack.pop()

      if (!previousClip) {
        return state
      }

      return {
        clip: previousClip,
        undoStack: [...state.undoStack, previousClip],
        redoStack: state.redoStack
      }
    }
    default:
  }

  return state
}

function useClipEditor() {
  return useReducer<Reducer<ClipEditorState, ClipEditorAction>>(reducer, { clip: null, undoStack: [], redoStack: []})
}

export default useClipEditor
