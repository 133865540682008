import React, {ReactNode} from "react";
import {Button, Card, Col, List, Row, Space, Tag, Typography} from "antd";
import Meta from "antd/es/card/Meta";
import {CloudOutlined, ShareAltOutlined, TeamOutlined, UserAddOutlined, UserOutlined} from "@ant-design/icons";
import FilmOutlined from "../icons/FilmOutlined/FilmOutlined";
import {useHistory} from "react-router-dom";
import RouteConstants from "../../util/RouteConstants";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

const {Title} = Typography

type Feature = {
  icon: React.ReactNode
  title: string
  description: string
}

const blueTextStyle: React.CSSProperties = {color: '#001529'}

interface Props {
  signUpLinks?: boolean
  selectedSubscription?: string
  header?: ReactNode
  annual?: boolean
}

function SubscriptionOptions({signUpLinks, selectedSubscription, header, annual}: React.PropsWithChildren<Props>) {

  const history = useHistory()
  const screens = useBreakpoint()

  const featureRenderer = (item: Feature) => {
    return <List.Item>
      <List.Item.Meta avatar={item.icon} title={item.title} description={item.description} style={{...blueTextStyle}}></List.Item.Meta>
    </List.Item>
  }

  const selectedStyle: React.CSSProperties = {border: '#48b89a 2px solid'}

  const columnStyle: React.CSSProperties = {marginBottom: screens.xs ? 32 : 0}

  return (
    <Row gutter={32} style={{alignItems: 'stretch'}}>
      {header}
      <Col xs={{span: 24}} sm={{span: 12}} style={{...columnStyle}}>
        <Card
          title={
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <Meta title="Analyst" avatar={<UserOutlined/>} style={{...blueTextStyle}}/>
            </div>
          }
          style={{height: '100%', ...(selectedSubscription === 'analyst' ? selectedStyle: {})}}
        >
          <Space
          direction="vertical"
          style={{width: '100%'}}
        >
          <Space size="small" direction={screens.xs ? "vertical" : "horizontal"}>
            <Tag
            style={{
              background: 'rgba(207, 19, 34, .3)',
              borderColor: 'rgba(207, 19, 34, .3)',
              height: 'fit-content'
            }}
            color="red"
          >Early Adopter Discount</Tag><Title level={3} style={{margin: 0, color: 'rgba(207, 19, 34, 1)'}}><s style={{color: 'rgba(207, 19, 34, 1)'}}>{annual ? '$90/yr' :'$10/mo'}</s></Title><Title level={3} style={{margin: 0, ...blueTextStyle}}>{annual ? '$45/yr' :'$5/mo'}</Title></Space>

            {
              signUpLinks &&
                <Button
                    style={{
                      width: '100%',
                      bottom: 0
                    }}
                    type="primary"
                    onClick={() => history.push(RouteConstants.REGISTER)}
                >
                    Sign up
                </Button>
            }
            <List
              itemLayout="horizontal"
              dataSource={[
                {
                  icon: <FilmOutlined/>,
                  title: 'Clip Editor',
                  description: 'Create as many clips as you want'
                },
                {
                  icon: <CloudOutlined/>,
                  title: 'Unlimited Storage',
                  description: 'Create clips without worrying about storage space',
                }
              ]}
              renderItem={featureRenderer}
            ></List>
          </Space>
        </Card>
      </Col>
      <Col xs={{span: 24}} sm={{span: 12}} style={{...columnStyle}}>
        <Card title={
          <Meta title={<>Team</>} avatar={<TeamOutlined/>} style={{...blueTextStyle}}/>
        }
              bodyStyle={{height: 'calc(100% - 54px)'}}>
          <Space direction="vertical" style={{width: '100%'}}>
            <Title level={3} style={{margin: 0, ...blueTextStyle}}>Coming Soon</Title>
            {
              signUpLinks &&
                <Button style={{width: '100%'}} type="primary" disabled>
                    Coming Soon
                </Button>
            }
            <List

              dataSource={[
                {
                  icon: <FilmOutlined/>,
                  title: 'Clip Editor',
                  description: 'Create as many clips as you want'
                },
                {
                  icon: <CloudOutlined/>,
                  title: 'Unlimited Storage',
                  description: 'Create clips without worrying about storage space',
                },
                {
                  icon: <ShareAltOutlined/>,
                  title: 'Share Clips',
                  description: 'Grant your coaches access to your team\'s shared clips'
                },
                {
                  icon: <UserAddOutlined/>,
                  title: 'Discounted Users',
                  description: 'Add users to your team at half the cost of normal membership'
                }
              ]}
              renderItem={featureRenderer}
            ></List>
          </Space>
        </Card>
      </Col>
    </Row>
  )
}

export default SubscriptionOptions;
