import TagDto from "../../services/tag/TagDto";

export default class Tag {
  name: string

  constructor(dto: TagDto) {
    this.name= dto.name
  }

  asDto() {
    return {
      name: this.name
    } as TagDto
  }
}
