import React, {MutableRefObject, useCallback, useEffect, useRef, useState} from "react";
import Annotation from "../../../types/annotation/Annotation";
import {calculateElapsedPauseSeconds, isInPauseRange} from "../FilmVideoPlayer";
import Narration from "../../../types/narration/Narration";

function calculateWhiteboardSize(video: HTMLVideoElement): { width: number, height: number } {
  const aspectRatio = video.videoWidth / video.videoHeight

  if (aspectRatio > 16/9) {
    return {
      width: 1280,
      height: 720 * 16 / 9 / aspectRatio
    }
  } else {
    return {
      width: 1280 * 9 / 16 * aspectRatio,
      height: 720
    }
  }
}

function useMultiTrackMedia(annotations: Annotation[], narrations: Narration[], videoPlayerRef: React.RefObject<HTMLVideoElement>, trimRange: number[] | null) {

  const [isPlaying, setIsPlaying] = useState<boolean>(false)
  const [isReady, setIsReady] = useState<boolean>(false)
  const [duration, setDuration] = useState<number>()
  const [isDragging, setIsDragging] = useState<boolean>()
  const [currentTime, setCurrentTime] = useState<number>(0)
  const [whiteboardSize, setWhiteboardSize] = useState<{height: number, width: number}>({height: 720, width: 1280})

  const audioPlayerMapRef = useRef<Map<string, MutableRefObject<HTMLAudioElement | null>>>(new Map())

  const updateCurrentTime = useCallback(() => {

    const interval = setInterval(() => {

      if (!videoPlayerRef.current) {
        return
      }

      if (isDragging || !isPlaying) {
        return
      }

      narrations.forEach(n => {

        const player = audioPlayerMapRef.current.get(n.id)?.current

        if (currentTime > n.startSecond && currentTime < n.endSecond) {


          if (player?.paused) {
            player.currentTime = currentTime
            player.play()
          }
        } else {
          if (player && !player?.paused) {
            player.pause()
          }
        }
      })

      if (isInPauseRange(currentTime, annotations)) {
        setCurrentTime(currentTime + 1/60)
      } else {
        setCurrentTime(videoPlayerRef.current?.currentTime + calculateElapsedPauseSeconds(currentTime, annotations))
      }

    }, 1000/60)

    return () => clearInterval(interval)

  }, [annotations, currentTime, isDragging, isPlaying, narrations, videoPlayerRef])

  const pauseVideo = useCallback(() => {

    videoPlayerRef.current?.pause()
  }, [videoPlayerRef])

  useEffect(() => {

    return updateCurrentTime()

  }, [isPlaying, updateCurrentTime, pauseVideo])

  const playVideo = useCallback(async () => {

    try {
      await videoPlayerRef.current?.play()
    } catch (err) { /* empty */ }

  }, [videoPlayerRef])


  useEffect(() => {

    const inPauseRange = isInPauseRange(currentTime, annotations)

    if (inPauseRange || isDragging || !isPlaying) {
      pauseVideo()
      return
    }

    if (!inPauseRange) {
      playVideo()
      return
    }

    // Check if narration audio should be playing

  }, [annotations, currentTime, isDragging, isPlaying, pauseVideo, playVideo])

  const play = () => {
    setIsPlaying(true)
  }

  const pause = () => {
    setIsPlaying(false)
  }

  const seekTo = useCallback((time: number) => {
    setCurrentTime(time)
    if (videoPlayerRef.current) {
      videoPlayerRef.current.currentTime = time - calculateElapsedPauseSeconds(time, annotations)
    }

    // Seek to narration time if seek is within narration range
    narrations.forEach(n => {
      if (time > n.startSecond && time < n.endSecond) {

        const player = audioPlayerMapRef.current.get(n.id)?.current


        if (player) {
          player.currentTime = time - n.startSecond
        }
      }
    })

  }, [annotations, narrations, videoPlayerRef])

  useEffect(() => {

    if (!trimRange) {
      return
    }

    if (currentTime < trimRange[0] || currentTime > trimRange[1]) {
      seekTo(trimRange[0] + 0.001)
    }
  }, [currentTime, seekTo, trimRange])

  const handleIsReady = () => {
    setIsReady(true)
  }

  const handleDurationChange = (e: React.SyntheticEvent<HTMLVideoElement>) => {
    setDuration(e.currentTarget.duration)
  }

  const handleIsDraggingChange = (value: boolean) => {
    setIsDragging(value)
  }

  const handleLoadedMetadata = () => {
    setWhiteboardSize(calculateWhiteboardSize(videoPlayerRef.current!))
  }

  return {
    currentTime,
    play,
    pause,
    seekTo,
    isPlaying,
    isReady,
    handleIsReady,
    duration,
    handleDurationChange,
    handleIsDraggingChange,
    audioPlayerMapRef,
    handleLoadedMetadata,
    whiteboardSize
  }
}

export default useMultiTrackMedia
