import React, {PropsWithChildren, useContext} from "react";
import {Redirect, Route, RouteProps} from "react-router";
import {UserContext} from "../../../contexts/user/UserContext";
import RouteConstants from "../../../util/RouteConstants";
import {useLocation} from "react-router-dom";

export const SecureRoute: React.FC<PropsWithChildren<RouteProps>> = props => {

  const {user} = useContext(UserContext);

  const location = useLocation()

  if (!localStorage.getItem('xsrf-token') || !user) {

    return (
      <Route>
        <Redirect
          to={{
            pathname: RouteConstants.LOGIN,
            state: {from: props.location}
          }}
        />
      </Route>
    )
  }

  if (!user.isEmailConfirmed && location.pathname !== RouteConstants.CONFIRM_EMAIL) {
    return (
      <Route>
        <Redirect
          to={{
            pathname: RouteConstants.CONFIRM_EMAIL,
            state: {from: props.location}
          }}
        />
      </Route>
    )
  }

  const subscriptionStatus = user.subscriptions.find(s => s.product === 'ANALYST')?.subscriptionStatus

  if (location.pathname !== RouteConstants.SUBSCRIBE_SUCCESS && subscriptionStatus !== 'ACTIVE' && subscriptionStatus !== 'TRIALING' && location.pathname !== RouteConstants.CONFIRM_EMAIL && location.pathname !== RouteConstants.SUBSCRIBE) {
    return (
      <Route>
        <Redirect
          to={{
            pathname: RouteConstants.SUBSCRIBE,
            state: {from: props.location}
          }}
        />
      </Route>
    )
  }


  return (
    <Route>
      {props.children}
    </Route>
  )
};
