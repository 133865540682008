import NarrationDto from "../../services/narration/NarrationDto";
import BucketObject from "../bucketobject/BucketObject";
import Narration from "./Narration";

function asNarration(dto: NarrationDto) {
  return {
    id: dto.id,
    startSecond: dto.startSecond,
    endSecond: dto.endSecond,
    audio: dto.audio ? new BucketObject(dto.audio) : null
  } as Narration
}

function asDto(narration: Narration) {
  return {
    id: narration.id,
    startSecond: narration.startSecond,
    endSecond: narration.endSecond,
    audio: narration.audio.asDto()
  } as NarrationDto
}

const NarrationMapper = {
  asNarration,
  asDto
}

export default NarrationMapper
