import AuthService from "../../services/auth/AuthService";
import User from "../../types/user/User";
import UserService from "../../services/user/UserService";

export const useRegisterModel = (setUser: (user: User) => void) => {

  const register = async (email: string, password: string, confirmPassword: string, referredBy: string | null) => {
    if (!(await AuthService.register(email, password, confirmPassword, referredBy))) {
      return false;
    }

    const user = await UserService.getUserDetails()

    if (user) {
      setUser(new User(user))
    }
    return true;
  }

  return {
    register
  }
}
