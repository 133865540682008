import React, {useState} from "react";
import Tag from "../../types/tag/Tag";
import {AutoComplete, Tag as AntTag} from "antd"
import {PlusOutlined} from "@ant-design/icons";
import {useQuery} from "react-query";
import TagService from "../../services/tag/TagService";

interface Props {
  tags: Tag[]
  onTagsChange: (tags: Tag[]) => void
  editable?: boolean
}

function TagList({tags, onTagsChange, editable}: React.PropsWithChildren<Props>) {

  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>()

  const {data} = useQuery('tagOptions', TagService.getAll)

  const addTag = (tag: Tag) => {
    inputValue && onTagsChange(tags.concat(tag))
  }

  const handleConfirm = () => {
    if (inputValue) {
      addTag(new Tag({name: inputValue}))
    }
    setIsAdding(false)
  }

  const handleSelect = () => (value: string) => {

    if (!value) {
      return
    }
    addTag(new Tag({name: value}))
    setIsAdding(false)
  }

  const handleClickAddTag = () => {
    setIsAdding(true)
  }

  const handleRemoveTag = (e: React.MouseEvent<HTMLElement>, tag: Tag) => () => {
    e.preventDefault()
    onTagsChange(tags.filter(t => t.name !== tag.name))
  }

  return (<>
    {tags.map(t => <AntTag closable={editable} onClose={(e) => handleRemoveTag(e, t)}>{t.name}</AntTag>)}
    {
      isAdding &&
      <AutoComplete
        options={inputValue && inputValue !== '' ? data?.map(t => new Tag(t)).filter(t => t.name.startsWith(inputValue))?.map(t => { return {value: t.name, label: t.name}}) : []}
        autoFocus
        style={{width: 78}}
        onBlur={handleConfirm}
        onKeyDown={e => e.key === 'Enter' && handleConfirm()}
        onSelect={handleSelect}
        onChange={setInputValue}
      ></AutoComplete>
    }
    {
      editable &&
      !isAdding &&
      <AntTag onClick={handleClickAddTag} style={{borderStyle: 'dashed'}}><PlusOutlined/> Add Tag</AntTag>
    }
  </>)
}

export default TagList;
