import UserPreferencesDto from "./UserPreferencesDto";
import FilmServiceClient from "../FilmServiceClient";
import {message} from "antd";

const getUserPreferences = async () => {

  let userPreferences: UserPreferencesDto | null = null

  try {
    const resp = await FilmServiceClient.get<UserPreferencesDto>('user-preferences')

    userPreferences = resp.data
  } catch (err: unknown) {
    message.error((err as Error).message)
  }

  return userPreferences
}

const updateUserPreferences = async(newUserPreferences: UserPreferencesDto) => {
  let userPreferences: UserPreferencesDto | null = null

  try {
    const resp = await FilmServiceClient.put<UserPreferencesDto>('user-preferences', newUserPreferences)

    userPreferences = resp.data
  } catch (err: unknown) {
    message.error((err as Error).message)
  }

  return userPreferences
}

const UserPreferencesService = {
  getUserPreferences,
  updateUserPreferences
}

export default UserPreferencesService
