import S3Client from "../S3Client";
import {message} from "antd";

const getBucketObject = async (presignedUrl: string) => {
  let bucketObject: Blob | null = null;

  try {
    const resp = await S3Client.get(presignedUrl, {
      headers: {
        "Content-Type": 'video/mp4'
      },
      responseType: 'blob'
    })

    bucketObject = new Blob([resp.data], {type: 'video/mp4'});
  } catch (err: unknown) { /* empty */ }
  return bucketObject;
}

const putBucketObject = async (
  presignedUrl: string,
  file: Blob,
  contentType: string,
  handleUploadProgress?: (progressEvent: ProgressEvent) => void
) => {

  let success = false;
  try {
    await S3Client.put(
      presignedUrl,
      file,
      {
        headers: {
          "Content-Type": contentType
        },
        onUploadProgress: handleUploadProgress
      }
    );
    success = true;
  } catch (err: unknown) {
    message.error((err as Error).message);
  }

  return success
}

const S3Service = {
  getBucketObject,
  putBucketObject,
}

export default S3Service;
