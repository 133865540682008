import React, {useCallback, useEffect, useRef} from "react";
import {Button} from "antd";
import GrabHorizontalOutlined from "../../../icons/GrabHorizontalOutlined/GrabHorizontalOutlined";
import GrabVerticalOutlined from "../../../icons/GrabVerticalOutlined/GrabVerticalOutlined";
import MathUtils from "../../../../util/MathUtils";

interface Props {
  trimRange: number[]
  onTrimRangeChange: (valueChanged: "start" | "end") => (trimRange: number[]) => void
  videoLength: number
  onIsDraggingChange: (value: boolean) => void
}

function Trimmer({trimRange, onTrimRangeChange, videoLength, onIsDraggingChange}: React.PropsWithChildren<Props>) {

  const containerRef = useRef<HTMLDivElement>(null)
  const hoveredHandle = useRef<"start" | "end" | null>()
  const grabbedHandle = useRef<"start" | "end" | null>()
  const isMouseDown = useRef<boolean>()

  const handleMouseEnter = (handle: "start" | "end") => () => {
    hoveredHandle.current = handle
  }

  const handleMouseLeave = (handle: "start" | "end") => () => {
    if (hoveredHandle.current === handle) {
      hoveredHandle.current = null
    }
  }

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {

      if (!grabbedHandle.current || !containerRef.current) {
        return
      }

      const mousePosition = Math.min(e.pageX - containerRef.current.getBoundingClientRect().x + containerRef.current.scrollLeft, containerRef.current.scrollWidth)

      if (grabbedHandle.current === "start") {
        trimRange[0] = (mousePosition + 6) * videoLength / (containerRef.current.scrollWidth)
        onTrimRangeChange("start")([...trimRange])
      } else if (grabbedHandle.current === "end") {
        trimRange[1] = (mousePosition - 6) * videoLength / (containerRef.current.scrollWidth)
        onTrimRangeChange("end")([...trimRange])
      } else {
        // Shouldn't happen
      }

    }, [onTrimRangeChange, trimRange, videoLength]
  )

  const handleMouseDown = useCallback(
    () => {
      isMouseDown.current = true
      onIsDraggingChange(true)
      if (hoveredHandle.current) {
        grabbedHandle.current = hoveredHandle.current
      }
    }, [onIsDraggingChange]
  )

  const handleMouseUp = useCallback(
    () => {
      onIsDraggingChange(false)
      grabbedHandle.current = null
      isMouseDown.current = false
    }, [onIsDraggingChange]
  )


  useEffect(
    () => {

      document.addEventListener(
        'mousedown',
        handleMouseDown
      )
      document.addEventListener(
        'mouseup',
        handleMouseUp
      )
      document.addEventListener(
        'mousemove',
        handleMouseMove
      )

      return () => {
        document.removeEventListener(
          'mousedown',
          handleMouseDown
        )
        document.removeEventListener(
          'mouseup',
          handleMouseUp
        )
        document.removeEventListener(
          'mousemove',
          handleMouseMove
        )
      }
    },
    [handleMouseDown, handleMouseMove, handleMouseUp]
  )

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: -12,
        height: "100%",
        width: "calc(100% + 24px)",
        overflow: "hidden",
      }}
    >
      <div
        ref={containerRef}
        style={{
          position: "relative",
          left: 12,
          height: "100%",
          width: "calc(100% - 24px)"
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundColor: '#ff4d4f',
            height: '100%',
            opacity: 0.8,
            width: `${trimRange[0] / (videoLength) * 100}%`,
            zIndex: 9,
          }}
        />
        <Button
          className="hoverable draggable"
          style={{
            position: 'absolute',
            top: 0,
            left: `calc(${MathUtils.keepInRange(trimRange[0] / videoLength * 100, 0, 100)}% - 12px)`,
            zIndex: 10,
            transition: "none",
            borderRadius: '2px 2px 0 0',
            height: '100%',
            width: 12
          }}
          icon={<GrabHorizontalOutlined
            style={{
              fontSize: 28,
              height: '100%',
              display: 'flex',
              alignItems: "center",
              justifyContent: 'center',
              width: '100%'
            }}
          />}
          type="primary"
          onMouseEnter={handleMouseEnter('start')}
          onMouseLeave={handleMouseLeave("start")}
          danger
        />
        <Button
          className="hoverable draggable"
          style={{
            position: 'absolute',
            top: 0,
            right: `calc(${100 - MathUtils.keepInRange(trimRange[1] / videoLength * 100, 0, 100)}% - 12px)`,
            zIndex: 10,
            transition: 'none',
            borderRadius: '2px 2px 0 0',
            height: '100%',
            width: 12
          }}
          icon={<GrabVerticalOutlined
            style={{
              fontSize: 28,
              height: '100%',
              display: 'flex',
              alignItems: "center",
              justifyContent: 'center',
              width: '100%'
            }}
          />}
          type="primary"
          onMouseEnter={handleMouseEnter('end')}
          onMouseLeave={handleMouseLeave("end")}
          danger
        />
        <div
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            backgroundColor: '#ff4d4f',
            height: '100%',
            opacity: 0.8,
            width: `${(1 - trimRange[1] / (videoLength)) * 100}%`,
            zIndex: 9,
          }}
        />
      </div>
    </div>
  )
}

export default Trimmer;
