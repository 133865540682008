import AnnotationDto from "../../services/annotation/AnnotationDto";
import BucketObject from "../bucketobject/BucketObject";
import AnnotationShape from "./AnnotationShape";
import Annotation from "./Annotation";

function asAnnotation(dto: AnnotationDto) {
  return {
    id: dto.id,
    startSecond: dto.startSecond,
    endSecond: dto.endSecond,
    overlayImage: dto.overlayImage ? new BucketObject(dto.overlayImage) : null,
    isPausedDuring: dto.isPausedDuring,
    shapes: dto.shapes.map(s => new AnnotationShape(s))
  } as Annotation
}

function asDto(annotation: Annotation) {
  return {
    id: annotation.id,
    startSecond: annotation.startSecond,
    endSecond: annotation.endSecond,
    overlayImage: annotation.overlayImage ? annotation.overlayImage.asDto() : null,
    isPausedDuring: annotation.isPausedDuring,
    shapes: annotation.shapes
  } as AnnotationDto
}

const AnnotationMapper = {
  asAnnotation,
  asDto
}

export default AnnotationMapper
