import React, {useContext, useState} from "react";
import {ConfigProvider, Layout, Menu, Space, Switch, Tag, theme} from "antd";
import RouteConstants from "../../util/RouteConstants";
import {useHistory} from "react-router-dom";
import logo from "../../resources/logo_with_text.svg";
import PortalSessionService from "../../services/portalsession/PortalSessionService";
import {Key} from "antd/lib/table/interface";
import GuideModal from "../../components/GuideModal/GuideModal";
import {BulbOutlined} from "@ant-design/icons";
import AudioInputPicker from "../../components/AudioInputPicker/AudioInputPicker";
import BugReport from "../../components/BugReport/BugReport";
import FeatureRequest from "../../components/FeatureRequest/FeatureRequest";
import {UserPreferencesContext} from "../../contexts/userpreferences/UserPreferencesContext";
import UserPreferences from "../../types/userpreferences/UserPreferences";

const {Content, Header, Sider} = Layout;

interface Props {
  children: React.ReactNode;
}

export const AppLayout: React.FC<Props> = props => {

  const history = useHistory();

  const {userPreferences, setUserPreferences} = useContext(UserPreferencesContext)

  const [isGuideVisible, setIsGuideVisible] = useState(false)
  const [openKeys, setOpenKeys] = useState<string[]>([])
  const [isChecked, setIsChecked] = useState(userPreferences?.preferredTheme === "dark")

  const handleClickManageSubscription = async () => {
    const portalSessionUrl = await PortalSessionService.createPortalSession()

    if (portalSessionUrl) {
      window.location.href = portalSessionUrl
    }
  }

  const handleThemeChange = (checked: boolean) => {

    setIsChecked(checked)

    const theme = checked ? "dark" : "light"

    if (userPreferences) {
      const updatedUserPreferences = userPreferences.asDto()
      updatedUserPreferences.preferredTheme = theme
      setUserPreferences(new UserPreferences(updatedUserPreferences))
    } else {
      const newUserPreferences = new UserPreferences({
        strokeWidth: 3,
        lineTension: 0,
        fontSize: 32,
        colorPalettes: {},
        preferredTheme: theme
      })

      setUserPreferences(newUserPreferences)
    }
  }

  const handleOpenGuideMenuChange = (keys: Key[]) => {
    setOpenKeys(keys.length > 0 ? keys.map(k => k as string).filter(k => k !== openKeys[0]) : [])
  }

  return (
    <ConfigProvider
      theme={{
        token: {colorPrimary: '#28AC8D'},
        algorithm: isChecked ? theme.darkAlgorithm : theme.defaultAlgorithm
      }}
    >
      <Layout>
        <Header
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            height: '10vh',
            alignItems: 'center'
          }}
        >

          <div
            className="logo"
            style={{
              margin: 8,
              display: 'flex'
            }}
          >
            <Space style={{height: '100%'}}>
              <img
                style={{
                  height: '8vh'
                }}
                src={logo}
                alt=""
              />
              <Tag
                style={{
                  background: 'rgba(207, 19, 34, .3)',
                  borderColor: 'rgba(207, 19, 34, .3)'
                }}
                color="red"
              >Beta</Tag>
            </Space>
          </div>
          <Space>
            <Menu
              mode="horizontal"
              theme="dark"
              selectedKeys={[]}
            >
              <Menu.Item>
                <FeatureRequest></FeatureRequest>
              </Menu.Item>
              <Menu.Item>
                <BugReport></BugReport>
              </Menu.Item>
              <Menu.Item>
                <AudioInputPicker></AudioInputPicker>
              </Menu.Item>
              <Menu.Item>
                <Switch checkedChildren={<BulbOutlined/>} unCheckedChildren={<BulbOutlined  style={{color: "black"}}/>} onChange={handleThemeChange} style={{backgroundColor: isChecked ? "#141414" : "white"}}></Switch>
              </Menu.Item>
              <Menu.Item
                key="3"
                onClick={() => {
                  setIsGuideVisible(true)
                }}
              >
                Guide
              </Menu.Item>
              <Menu.Item
                key="2"
                onClick={handleClickManageSubscription}
              >
                Manage Subscription
              </Menu.Item>
              <Menu.Item
                key="1"
                onClick={() => {
                  localStorage.removeItem('xsrf-token')
                  history.push(RouteConstants.LANDING_PAGE)
                }}
              >Logout</Menu.Item>
            </Menu>
          </Space>
        </Header>
        <Layout>
          <Sider>
            <Menu theme="dark">
              {/*<Menu.Item*/}
              {/*  disabled*/}
              {/*  key="1"*/}
              {/*>Games <Tag*/}
              {/*  style={{*/}
              {/*    background: 'rgba(207, 19, 34, .3)',*/}
              {/*    borderColor: 'rgba(207, 19, 34, .3)'*/}
              {/*  }}*/}
              {/*  color="red"*/}
              {/*>Coming Soon</Tag></Menu.Item>*/}
              <Menu.Item
                key="2"
                onClick={() => history.push(RouteConstants.CLIPS)}
              >Clips</Menu.Item>
            </Menu>
          </Sider>
          <Content
            style={{
              padding: 24,
              margin: 0,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: 'calc(100vh - 200px)'
            }}
          >
            <GuideModal
              visible={isGuideVisible}
              openKeys={openKeys}
              onOpenChange={handleOpenGuideMenuChange}
              onCancel={() => setIsGuideVisible(false)}
            />
            {props.children}
          </Content>
        </Layout>
      </Layout>
    </ConfigProvider>
  )
}
