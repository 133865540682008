import {MutableRefObject, RefObject, useRef, useState} from "react";
import {RadioChangeEvent} from "antd";
import Konva from "konva";
import {RGBColor} from "react-color";
import AnnotationShape, {AnnotationShapeType} from "../../../types/annotation/AnnotationShape";
import Annotation from "../../../types/annotation/Annotation";
import AnnotationUtils from "../../../types/annotation/AnnotationUtils";
import {ClipEditorAction} from "../../../views/clip/useClipEditor";

export type LayerShape = {
  id: string
  type: AnnotationShapeType
  points: number[]
  color: RGBColor
  blockAngle?: number
};

const useWhiteBoardModel = (
  currentTime: number,
  annotations: Annotation[],
  clipEditorDispatch: React.Dispatch<ClipEditorAction>,
  getAnnotationFilePathRef?: MutableRefObject<(((id: string) => string | undefined) | undefined)>,
  pixelRatio?: number
) => {

  const [tool, setTool] = useState<AnnotationShapeType>(AnnotationShapeType.LINE);
  const stageMapRef = useRef<Map<string, RefObject<Konva.Stage>>>(new Map())

  const getAnnotationFilePath = (id: string) => {
    console.log(pixelRatio)
    return stageMapRef.current.get(id)
                      ?.current
                      ?.getStage()
                      .toDataURL({pixelRatio: pixelRatio})
  }

  if (getAnnotationFilePathRef) {
    getAnnotationFilePathRef.current = getAnnotationFilePath
  }

  const handleShapesChange = (id: string) => (shapes: AnnotationShape[]) => {
    const annotation = annotations.find(l => l.id === id);

    if (annotation) {
      annotation.shapes = shapes

      clipEditorDispatch({type: "UPDATE_ANNOTATION_SHAPES", annotation})
    }
  }

  const handleToolChange = (event: RadioChangeEvent) => {
    setTool(event.target.value)
  }

  const handleTimeRangeChange = (id: string) => (values: number[]) => {

    const annotation = annotations.find(l => l.id === id);
    if (annotation) {
      annotation.startSecond = values[0] / 1000;
      annotation.endSecond = values[1] / 1000;


      clipEditorDispatch({type: "UPDATE_ANNOTATION_TIME_RANGE", annotation})
    }
  }

  const handleClickClear = () => {
    const currentAnnotation = annotations.find(a => AnnotationUtils.timeRangeContains(a, currentTime))

    if (!currentAnnotation) {
      return
    }

    handleShapesChange(currentAnnotation.id)(
      [])
  }


  return {
    tool,
    handleShapesChange,
    handleToolChange,
    handleTimeRangeChange,
    stageMapRef,
    handleClickClear
  }
}

export default useWhiteBoardModel;
