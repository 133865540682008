import React, {useCallback, useContext, useEffect} from "react";
import {Button, Card, Spin, Typography} from "antd";
import {UserContext} from "../../contexts/user/UserContext";
import {useHistory, useLocation} from "react-router-dom";
import UserService from "../../services/user/UserService";
import RouteConstants from "../../util/RouteConstants";
import User from "../../types/user/User";
import {RedoOutlined} from "@ant-design/icons";
import {Redirect, Route} from "react-router";

const {Text} = Typography

function ConfirmEmail() {

  const {user, setUser} = useContext(UserContext)

  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(location.search)

  const token = params.get("token")

  const confirmEmail = useCallback(async () => {
    if (!token) {
      return
    }

    const userDto = await UserService.confirmEmail(token)

    if (!userDto) {
      return
    }

    setUser(new User(userDto))

    history.push(RouteConstants.SUBSCRIBE)
  }, [history, setUser, token])

  useEffect(() => {
    confirmEmail()
  }, [confirmEmail, token])

  const handleClickResend = () => {
    UserService.resendConfirmationEmail()
  }

  if (user?.isEmailConfirmed) {
    return (
      <Route>
        <Redirect
          to={{
            pathname: RouteConstants.SUBSCRIBE,
          }}
        />
      </Route>
    )
  }

  return (
    <Card title="Confirm Email">
      {
        token &&
        <Spin></Spin>
      }
      {
        !token &&
          <Text>You should receive a confirmation email at <mark style={{background: 'none', color: '#28AC8D'}}>{user?.email}</mark> shortly.<br/><br/>
              Click in the link in the email to proceed.<br/><br/>
              <Button icon={<RedoOutlined/>} onClick={handleClickResend}>Resend</Button>
          </Text>
      }

    </Card>)
}

export default ConfirmEmail;
