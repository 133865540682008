import React, {useRef, useState} from "react";
import {ToolOutlined} from "@ant-design/icons";
import {Checkbox, Form, Input, Modal} from "antd";
import {FormInstance} from "antd/es/form/Form";
import FeatureRequestService from "../../services/featureRequest/FeatureRequestService";
import FeatureRequestDto from "../../services/featureRequest/FeatureRequestDto";

const {TextArea} = Input;

function FeatureRequest() {

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const formRef = useRef<FormInstance>(null);

  const handleOk = () => {
    formRef.current?.submit()
    setIsOpen(false)
  }

  const handleFinish = (values: unknown) => {
    FeatureRequestService.create(values as FeatureRequestDto)
    formRef.current?.resetFields()
  }

  return (
    <>
      <ToolOutlined title="Request Feature" onClick={() =>setIsOpen(true)}></ToolOutlined>
      <Modal title="Request a Feature" open={isOpen} onCancel={() => setIsOpen(false)} okText="Request" onOk={handleOk}>
        <Form
          ref={formRef}
          onFinish={handleFinish}
        >
          <Form.Item name="description" rules={[{required: true, message: "Please add a description"}]}>
            <TextArea rows={4} placeholder="Please describe the feature"></TextArea>
          </Form.Item>
          <Form.Item name="isOkToContact" valuePropName="checked" required initialValue={true}>
            <Checkbox defaultChecked>We may contact you for clarification about the feature</Checkbox>
          </Form.Item>
        </Form>
      </Modal></>)
}

export default FeatureRequest;
