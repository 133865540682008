import React, {useRef} from "react";
import {Avatar, Button, Card, Col, Row, Space, Typography} from "antd";
import CodyPic from "./tymnVM5I.jpg";
import {
  BulbOutlined, ClockCircleOutlined, EditOutlined, LineChartOutlined, SyncOutlined, TwitterOutlined,
} from "@ant-design/icons";
import "./LandingPage.less"
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import SubscriptionOptions from "../../components/SubscriptionOptions/SubscriptionOptions";
import RouteConstants from "../../util/RouteConstants";
import {useHistory} from "react-router-dom";

const {
  Title,
  Text,
  Link
} = Typography


const blueTextStyle: React.CSSProperties = {color: '#001529'}

const mintToWhiteGradientStyle: React.CSSProperties = {borderImage: 'linear-gradient(to bottom, #28AC8D, white) 1 100%'}

const mintToBlueGradientStyle: React.CSSProperties = {borderImage: 'linear-gradient(to bottom, #28AC8D, #001529) 1 100%'}

const colStyle: React.CSSProperties = {marginBottom: 96, borderLeftWidth: 1, borderLeftStyle: 'solid', borderImage: 'linear-gradient(to bottom, #28AC8D, white) 1 100%'}

function NewComponent() {

  const history = useHistory()

  const screens = useBreakpoint()
  const coachesRef = useRef<HTMLDivElement>(null)
  const contentCreatorsRef = useRef<HTMLDivElement>(null)
  const pricingRef = useRef<HTMLDivElement>(null)

  const boxStyle = {width: screens.xs ? '80%' : '60%', padding: screens.xs ? '5vh 0' : '15vh 0'}

  return (<>
    <div className="diagonal-box bg-one first-diagonal" style={{paddingTop: '8vh', paddingBottom: 0}}>
      <div
        style={{
          width: screens.xs ? '90%' : '80%'
        }}
      >
        <Row gutter={32}>
          <Col xs={{span: 24}} sm={{span: 10}} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 32}}>
            <div>
              <Title style={{color: 'white'}}>Simple. Fast.</Title>
              <Title level={3} style={{color: 'white'}}>Turn a play into an install in minutes.</Title>
              <Button size="large" onClick={() => history.push(RouteConstants.REGISTER)}>Try for free</Button>
            </div>
          </Col>
          <Col xs={{span: 24}} sm={{span: 14}}>
            <div className="glass"
                 style={{
                   height: 'fit-content',
                   width: 'fit-content',
                   padding: 12
                 }}
            >
              <video src="https://filmbuddy-guide-videos.s3.amazonaws.com/demo-short.mp4" style={{maxWidth: '100%'}} width={852} controls ></video>
            </div>
          </Col>
        </Row>
      </div>
    </div>
    <div className="diagonal-box">
      <div style={{...boxStyle}}>
        <Row gutter={32}>
          <Col style={{width: 'fit-content'}} xs={{span: 24}} sm={{span: 12}}>
            <Title style={{color: '#001529', margin: 0, minWidth: 320}}>Coaches</Title>
            <p style={{color: '#001529',fontSize: '1.5rem'}}>Get more out of film sessions<br/><Link style={{fontSize: '1rem'}} onClick={() => coachesRef.current?.scrollIntoView({behavior: 'smooth'})}>Learn More</Link></p>
          </Col>
          <Col style={{width: 'fit-content'}} xs={{span: 24}} sm={{span: 12}}>
            <Title style={{color: '#001529', margin: 0, minWidth: 320}}>Content Creators</Title>
            <p style={{color: '#001529',fontSize: '1.5rem'}}>Increase your engagement in minutes<br/><Link style={{fontSize: '1rem'}} onClick={() => contentCreatorsRef.current?.scrollIntoView({behavior: 'smooth'})}>Learn More</Link></p>
          </Col>
        </Row>
      </div>
    </div>
    <div className="diagonal-box bg-coaches">
      <div className="bg-image-container">
        <div className="bg-image projector"/>
      </div>
      <div style={{...boxStyle}} ref={coachesRef}>
        <Row style={{width: 'fit-content'}} gutter={32}>
          <Col span={24}><Title level={2} style={{color: '#28AC8D'}} >Coaches</Title></Col>
          <Col span={24}><Title style={{color: 'white', margin: '2rem 0 6rem 0'}}>Maximize the limited time you have with your players</Title></Col>
          <Col xs={{span: 24}} sm={{span: 8}} style={{...colStyle, ...mintToWhiteGradientStyle}}>
            <ClockCircleOutlined
              style={{
                fontSize: '2rem',
                color: '#28AC8D',
                marginBottom: '0.5rem'
              }}
            />
            <Title level={4} style={{color: '#8BE5D0'}}>Less Drawing, More Teaching</Title>
            <p style={{color: 'white', fontSize: '1rem'}}>Don't spend time with players drawing fits, blocking schemes and coverages. Do it quickly and easily, beforehand.</p>
          </Col>
          <Col xs={{span: 24}} sm={{span: 8}} style={{...colStyle, ...mintToWhiteGradientStyle}}>
            <EditOutlined
              style={{
                fontSize: '2rem',
                color: '#28AC8D',
                marginBottom: '0.5rem'
              }}/>
            <Title level={4} style={{color: '#8BE5D0'}}>
              Legible Illustrations
            </Title>
            <p style={{color: 'white', fontSize: '1rem'}}>No more confusion about what a letter or route is.</p>
          </Col>
          <Col xs={{span: 24}} sm={{span: 8}} style={{...colStyle, ...mintToWhiteGradientStyle}}>
            <SyncOutlined
              style={{
                fontSize: '2rem',
                color: '#28AC8D',
                marginBottom: '0.5rem'
              }}
            ></SyncOutlined>
            <Title level={4} style={{color: '#8BE5D0'}}>Reusable</Title>
            <p style={{color: 'white', fontSize: '1rem'}}>Save your best clips and use them next season!</p>
          </Col>
        </Row>
      </div>
    </div>
    <div className="diagonal-box bg-three">
      <div className="bg-image-container">
        <div className="bg-image content-creator"/>
      </div>
      <div style={{...boxStyle}} ref={contentCreatorsRef}>
        <Row style={{width: 'fit-content'}} gutter={32}>
          <Col span={24}><Title level={2} style={{color: '#28AC8D'}} >Content Creators</Title></Col>
          <Col span={24}><Title style={{color: '#001529', margin: '2rem 0 6rem 0'}}>Engage and grow your audience</Title></Col>
          <Col xs={{span: 24}} sm={{span: 8}} style={{...colStyle, ...mintToBlueGradientStyle}}>
            <LineChartOutlined
              style={{
                fontSize: '2rem',
                color: '#28AC8D',
                marginBottom: '0.5rem'
              }}
            />
            <Title level={4} style={{color: '#0f4c4d'}}>Increase Engagement</Title>
            <p style={{color: '#001529', fontSize: '1rem'}}>Draw your audience in with graphics.</p>
          </Col>
          <Col xs={{span: 24}} sm={{span: 8}} style={{...colStyle, ...mintToBlueGradientStyle}}>
            <BulbOutlined
              style={{
                fontSize: '2rem',
                color: '#28AC8D',
                marginBottom: '0.5rem'
              }}/>
            <Title level={4} style={{color: '#0f4c4d'}}>
              Make Your Videos Approachable
            </Title>
            <p style={{color: '#001529', fontSize: '1rem'}}>With Film Buddy, laymen can appreciate your content, too.</p>
          </Col>
        </Row>
      </div>
    </div>
    <div className="diagonal-box bg-five">
      <div style={{...boxStyle}} ref={pricingRef}>
        <SubscriptionOptions signUpLinks header={
          <Col span={24}>
            <Title style={{fontSize: '2.5rem', ...blueTextStyle}}>Pricing</Title>
          </Col>}/>
      </div>
    </div>
    <div className="diagonal-box bg-four">
      <div style={{...boxStyle, display: "flex", justifyContent: "center"}}>
        <Row gutter={24}>
          <Col span={24}>
            <Card style={{width: 'fit-content'}}>
              <Space direction="vertical">
                <Avatar
                  src={<img
                    src={CodyPic}
                    alt=""
                  />}
                  size={256}
                />
                <Text italic>Film Buddy is an easy way to upgrade <br/> your video clips. Provide unique clips<br/> that
                  will capture your audience's <br/> attention, in just a few clicks.</Text>
                <Space>
                  <Text strong>- Cody Alexander</Text>
                  <Link href="https://twitter.com/The_Coach_A">@The_Coach_A <TwitterOutlined/></Link>
                </Space>
              </Space>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
    <div
      style={{
        display: 'flex',
        alignItems: "center",
        flexDirection: 'column',
        marginTop: '50px'
      }}
    >
      <Button
        style={{
          marginTop: 100,
          marginBottom: 100
        }}
        size="large"
        type="primary"
        onClick={() => history.push(RouteConstants.REGISTER)}
      >Sign Up</Button>
    </div>
  </>)
}

export default NewComponent;
