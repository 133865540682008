import React from "react";
import Icon from "@ant-design/icons";
import {ReactComponent as TensionOutlinedSvg} from "./TensionOutlined.svg"
import IconProps from "../IconProps";

interface Props extends IconProps {
}

function TensionOutlined(props: React.PropsWithChildren<Props>) {

  return (<Icon component={TensionOutlinedSvg} {...props} />)
}

export default TensionOutlined;
