import React from "react";
import {Button, Card, Input, Modal, Space} from "antd";
import FilmVideoPlayer from "../../components/filmvideoplayer/FilmVideoPlayer";
import useClipEditorModel from "./ClipEditorModel";
import {DeleteOutlined, DownloadOutlined} from "@ant-design/icons";
import TagList from "../../components/TagList/TagList";

const ClipEditor: React.FC = () => {

  const {
    clip,
    handleTitleChange,
    handleUpload,
    isUploading,
    getAnnotationFilePathRef,
    handleClickDownload,
    isRendering,
    url,
    isLoadingClip,
    uploadPercent,
    isDeleteModalVisible,
    handleClickDelete,
    handleDelete,
    handleCancelDelete,
    isDeleting,
    handleTagsChange,
    clipDispatch
  } = useClipEditorModel()


  return (<Card style={{width: "100%"}}>
    <Space
      direction="vertical"
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Space
        style={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Space>
          <Input
            style={{
              minWidth: 400,
              fontSize: 30,
              border: 0
            }}
            placeholder="Title"
            onChange={handleTitleChange}
            value={clip?.title}
          />
          <TagList tags={clip?.tags || []} onTagsChange={handleTagsChange} editable/>
        </Space>
        <Button icon={<DeleteOutlined/>} onClick={handleClickDelete} danger type="primary">Delete</Button>
        <Modal title="Delete Clip" visible={isDeleteModalVisible} onOk={handleDelete} okButtonProps={{danger: true, loading: isDeleting}} okText="Delete" onCancel={handleCancelDelete}>
          You are permanently deleting your clip!
        </Modal>
      </Space>
      <FilmVideoPlayer
        url={url}
        hasTrimRange
        onClickUpload={handleUpload}
        trimRange={clip && (clip.startSecond || clip.startSecond === 0) && (clip.endSecond || clip.endSecond === 0) ? [
          clip.startSecond, clip.endSecond
        ] : null}
        clipDispatch={clipDispatch}
        getAnnotationFilePathRef={getAnnotationFilePathRef}
        annotations={clip?.annotations || []}
        narrations={clip?.narrations || []}
        isUploading={isUploading}
        isLoadingClip={isLoadingClip}
        uploadPercent={uploadPercent}
        clipId={clip && clip.id ? clip.id : ''}
      />
    </Space>

    <Button
      type="primary"
      onClick={handleClickDownload}
      loading={isRendering}
      icon={<DownloadOutlined/>}
      shape="circle"
      size="large"
      title={isRendering ? "Rendering" : "Download"}
      style={{
        position: 'fixed',
        right: 60,
        bottom: 60
      }}
    />
  </Card>)
}

export default ClipEditor;
