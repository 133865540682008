import React from "react";
import WhiteboardLine from "./WhiteboardLine";
import {RGBColor} from "react-color";

interface Props {
  points: number[]
  color: RGBColor
  blockAngle: number
  blockLength: number
  isSelected?: boolean
  onSelect?: () => void
  onLineChange?: (points: number[]) => void
  onBlockAngleChange?: (blockAngle: number) => void
  scale?: number
  tension?: number
  strokeWidth?: number
  isDashed?: boolean
}

function WhiteboardBlock({
  points,
  color,
  blockAngle,
  blockLength,
  isSelected,
  onSelect,
  onLineChange,
  onBlockAngleChange,
  scale,
  tension,
  strokeWidth,
  isDashed
}: React.PropsWithChildren<Props>) {

  const onBlockLineChange = (blockPoints: number[]) => {
    if (blockPoints[0] !== points[points.length - 2] + Math.cos(blockAngle) * blockLength || blockPoints[1] !==
      points[points.length - 1] + Math.sin(blockAngle) * blockLength) {
      onBlockAngleChange && onBlockAngleChange(Math.atan2(
        blockPoints[1] - blockPoints[3],
        blockPoints[0] - blockPoints[2]
      ))
    } else if (blockPoints[4] !== points[points.length - 2] - Math.cos(blockAngle) * blockLength || blockPoints[5] !==
      points[points.length - 1] - Math.sin(blockAngle) * blockLength) {
      onBlockAngleChange && onBlockAngleChange(Math.atan2(
        blockPoints[5] - blockPoints[3],
        blockPoints[4] - blockPoints[2]
      ))
    } else {
      // do nothing
    }
  }

  return (<>
    <WhiteboardLine
      points={[
        points[points.length - 2] + Math.cos(blockAngle) * blockLength * (scale || 1),
        points[points.length - 1] + Math.sin(blockAngle) * blockLength * (scale || 1),
        points[points.length - 2],
        points[points.length - 1],
        points[points.length - 2] - Math.cos(blockAngle) * blockLength * (scale || 1),
        points[points.length - 1] - Math.sin(blockAngle) * blockLength * (scale || 1)
      ]}
      color={color}
      isSelected={isSelected}
      onSelect={onSelect}
      onLineChange={onBlockLineChange}
      scale={scale}
      strokeWidth={strokeWidth}
    />
    <WhiteboardLine
      points={points}
      color={color}
      isSelected={isSelected}
      onSelect={onSelect}
      onLineChange={onLineChange}
      scale={scale}
      tension={tension || 0}
      strokeWidth={strokeWidth}
      isDashed={isDashed}
    />
  </>)
}

export default WhiteboardBlock;
