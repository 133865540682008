import React from "react";
import Icon from "@ant-design/icons";
import {ReactComponent as CircleOutlinedSvg} from "./CircleOutlined.svg"
import IconProps from "../IconProps";

interface Props extends IconProps {
}

function CircleOutlined(props: React.PropsWithChildren<Props>) {

  return (<Icon component={CircleOutlinedSvg} {...props} />)
}

export default CircleOutlined;
