import React from "react";
import Icon from "@ant-design/icons";
import {ReactComponent as LineVerticalOutlinedSvg} from "./LineVerticalOutlined.svg"
import IconProps from "../IconProps";

interface Props extends IconProps {
}

function LineVerticalOutlined(props: React.PropsWithChildren<Props>) {

  return (<Icon component={LineVerticalOutlinedSvg} {...props} />)
}

export default LineVerticalOutlined;
