import {useState} from "react";

const useVolume = () => {

  const [volume, setVolume] = useState<number>(0);
  const [isMuted, setIsMuted] = useState<boolean>(false);

  const handleVolumeSliderChange = (value: number) => {
    setVolume(value);
    setIsMuted(false)
  }

  const handleMuteClicked = () => {
    setIsMuted(!isMuted);
  }
  return {
    volume: isMuted ? 0 : volume,
    handleVolumeSliderChange,
    handleMuteClicked,
  }
}

export default useVolume;
