import React from "react";
import IconProps from "../IconProps";
import Icon from "@ant-design/icons";
import {ReactComponent as CircleFilledSvg} from "./CircleFilled.svg";

interface Props extends IconProps {
}

function CircleFilled(props: React.PropsWithChildren<Props>) {

  return (<Icon component={CircleFilledSvg} {...props} />)
}

export default CircleFilled;
