import {useCallback, useEffect, useState} from "react";
import S3Service from "../services/s3service/S3Service";

const useBucketObject = (presignedUrl: string, isUploading: boolean) => {

  const [blobFilepath, setBlobFilepath] = useState<string>()
  const [isLoadingClip, setIsLoadingClip] = useState<boolean>(false)

  const fetchBlob = useCallback(
    async () => {
      if (!presignedUrl || isUploading) {
        return;
      }
      setIsLoadingClip(true)
      try {
        const blob = await S3Service.getBucketObject(presignedUrl);
        if (blob) {
          setBlobFilepath(URL.createObjectURL(blob))
        }
      } catch (e) { /* empty */ }
      setIsLoadingClip(false)
    },
    [presignedUrl, isUploading]
  )


  useEffect(
    () => {
      fetchBlob()
    },
    [fetchBlob, presignedUrl, isUploading]
  )

  return {
    blobFilepath,
    isLoadingClip
  }
}

export default useBucketObject;
