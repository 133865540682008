import React from "react";
import Icon from "@ant-design/icons";
import {ReactComponent as BlockOutlinedSvg} from "./BlockOutlined.svg"
import IconProps from "../IconProps";

interface Props extends IconProps {
}

function BlockOutlined(props: React.PropsWithChildren<Props>) {

  return (<Icon component={BlockOutlinedSvg} {...props} />)
}

export default BlockOutlined;
