import React, {useEffect, useState} from "react";
import UserPreferences from "../../types/userpreferences/UserPreferences";
import UserPreferencesService from "../../services/userpreferences/UserPreferencesService";

const initialState: {userPreferences: UserPreferences | null, setUserPreferences: (newUserPreferences: UserPreferences) => void} = {
  userPreferences: null, setUserPreferences: () => {}
}

const UserPreferencesContext = React.createContext<{userPreferences: UserPreferences | null, setUserPreferences: (newUserPreferences: UserPreferences) => void}>(initialState)

function UserPreferencesProvider(props: React.PropsWithChildren<object>) {

  const [userPreferences, setUserPreferences] = useState<UserPreferences | null>(null)

  const fetchUserPreferences = async () => {
    const up = await UserPreferencesService.getUserPreferences()

    console.log(up)

    if (up) {
      setUserPreferences(new UserPreferences(up))
    }
  }

  const updateUserPreferences = async (up: UserPreferences) => {
    const updated = await UserPreferencesService.updateUserPreferences(up.asDto())

    if (updated) {
      setUserPreferences(new UserPreferences(updated))
    }
  }

  useEffect(() => {
    fetchUserPreferences()
  }, [])

  return (
    <UserPreferencesContext.Provider value={{userPreferences, setUserPreferences: updateUserPreferences}}>
      {props.children}  
    </UserPreferencesContext.Provider>
  )
}

export {UserPreferencesProvider, UserPreferencesContext}
