import React from "react";
import Icon from "@ant-design/icons";
import {ReactComponent as CircleFilledOutlinedSvg} from "./CircleFilledOutlined.svg"
import IconProps from "../IconProps";

interface Props extends IconProps {
}

function CircleFilledOutlined(props: React.PropsWithChildren<Props>) {

  return (<Icon component={CircleFilledOutlinedSvg} {...props} />)
}

export default CircleFilledOutlined;
