import FeatureRequestDto from "./FeatureRequestDto";
import filmServiceClient from "../FilmServiceClient";

const create = async (featureRequest: FeatureRequestDto) => {

  try {
    filmServiceClient.post<boolean>("feature-request", featureRequest)
  } catch (e) {
    return false
  }

  return true
}

const FeatureRequestService = {
  create
}


export default FeatureRequestService;
