import React, {useCallback, useEffect, useRef} from "react";
import {EditOutlined, PlusOutlined} from "@ant-design/icons";
import Annotation from "../../../../types/annotation/Annotation";
import AnnotationSlider from "./AnnotationSlider";
import {Button, message} from "antd";
import AnnotationService from "../../../../services/annotation/AnnotationService";
import AnnotationMapper from "../../../../types/annotation/AnnotationMapper";
import {ClipEditorAction} from "../../../../views/clip/useClipEditor";

interface Props {
  annotations: Annotation[]
  videoLength: number
  clipEditorDispatch: React.Dispatch<ClipEditorAction>
  onIsHoveringAnnotationChange: (value: boolean) => void
  onCurrentTimeChange: (value: number) => void
  markerPosition: number
}

function AnnotationTimeline({annotations, videoLength, clipEditorDispatch, onIsHoveringAnnotationChange, onCurrentTimeChange, markerPosition}: React.PropsWithChildren<Props>) {

  const containerRef = useRef<HTMLDivElement>(null)
  const hoveredHandle = useRef<{annotationId: string, handle: "start" | "end"} | null>()
  const grabbedHandle = useRef<{annotationId: string, handle: "start" | "end"} | null>()
  const isMouseDown = useRef<boolean>()

  const handleMouseEnter = (annotationId: string) => (handle: "start" | "end") => {
    hoveredHandle.current = {annotationId, handle}
  }

  const handleMouseLeave = (annotationId: string) => (handle: "start" | "end") => {
    if (hoveredHandle.current?.handle === handle && hoveredHandle.current?.annotationId === annotationId) {
      hoveredHandle.current = null
    }
  }

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {

      if (!grabbedHandle.current || !containerRef.current) {
        return
      }

      const ann = annotations.find(a => a.id === grabbedHandle.current?.annotationId)

      if (!ann) {
        return
      }

      if (grabbedHandle.current?.handle === "start") {

        const value = (e.pageX - containerRef.current.getBoundingClientRect().x + containerRef.current.scrollLeft - 12) * videoLength / containerRef.current.scrollWidth
        ann.startSecond = value
        onCurrentTimeChange(value)

      } else if (grabbedHandle.current?.handle === "end") {
        const value = (e.pageX - containerRef.current.getBoundingClientRect().x + containerRef.current.scrollLeft + 12) * videoLength / containerRef.current.scrollWidth
        ann.endSecond = value
        onCurrentTimeChange(value)

      } else {
        // Shouldn't happen
      }
      clipEditorDispatch({type: "UPDATE_ANNOTATION_TIME_RANGE", annotation: ann})

    }, [annotations, clipEditorDispatch, onCurrentTimeChange, videoLength]
  )

  const handleMouseDown = useCallback(
    () => {
      isMouseDown.current = true
      if (hoveredHandle.current) {
        grabbedHandle.current = hoveredHandle.current
      }
    }, [isMouseDown]
  )

  const handleMouseUp = useCallback(
    () => {
      grabbedHandle.current = null
      isMouseDown.current = false
    }, [isMouseDown]
  )

  const handleClickAddAnnotation = (async () => {

    try {
      const ann = await AnnotationService.createAnnotation({
        id: '',
        startSecond: markerPosition,
        endSecond: markerPosition + 1,
        overlayImage: null,
        isPausedDuring: true,
        shapes: []
      })

      if (!ann) {
        message.error("There was an issue creating an annotation")
        return
      }

      clipEditorDispatch({type: "ADD_ANNOTATION", annotation: AnnotationMapper.asAnnotation(ann)})
    } catch (e) {
      message.error("There was an issue creating an annotation")
    }
  })

  useEffect(
    () => {

      document.addEventListener(
        'mousedown',
        handleMouseDown
      )
      document.addEventListener(
        'mouseup',
        handleMouseUp
      )
      document.addEventListener(
        'mousemove',
        handleMouseMove
      )

      return () => {
        document.removeEventListener(
          'mousedown',
          handleMouseDown
        )
        document.removeEventListener(
          'mouseup',
          handleMouseUp
        )
        document.removeEventListener(
          'mousemove',
          handleMouseMove
        )
      }
    },
    [handleMouseDown, handleMouseMove, handleMouseUp]
  )

  const handleClickDelete = (annotationId: string) => () => {

    const ann = annotations.find(a => a.id === annotationId)

    if (!ann) {
      return
    }

    clipEditorDispatch({type: "REMOVE_ANNOTATION", annotation: ann})
  }


  return (
    <div
      ref={containerRef}
      onMouseOver={() => onIsHoveringAnnotationChange(true)}
      onMouseOut={() => onIsHoveringAnnotationChange(false)}
      style={{width: '100%', height: 'fit-content', position: "relative"}}
    >
      <div
        style={{width: '100%', backgroundColor: 'rgba(40,172,141, .2)', display: 'flex', alignItems: 'center'}}
      >
        <div
          className="unselectable"
          style={{
            width: 'fit-content',
            height: 'fit-content',
            margin: 4,
            color: 'rgba(255, 255, 255, .83)'
          }}
        >
          Annotations <EditOutlined/>
        </div>
      </div>
      {
        annotations.map(a => {
          return (
            <AnnotationSlider
              key={a.id}
              left={`${a.startSecond / videoLength * 100}%`}
              width={`${(a.endSecond - a.startSecond) / videoLength * 100}%`}
              onMouseEnter={handleMouseEnter(a.id)}
              onMouseLeave={handleMouseLeave(a.id)}
              onClickDelete={handleClickDelete(a.id)}
            ></AnnotationSlider>
          )
          }
        )
      }
      {
        !grabbedHandle.current &&
        <Button
          onClick={handleClickAddAnnotation}
          style={{
            position: "absolute",
            top: 0,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderLeftWidth: 0,
            left: `${markerPosition / videoLength * 100}%`,
            height: "100%",
            transition: "none",
            zIndex: 25
          }}
          disabled={annotations
            .some(layer => {
              return markerPosition / 1000 > layer.startSecond && markerPosition / 1000 < layer.endSecond;
            })}
          type="text"
          icon={<PlusOutlined/>}
        />
      }
    </div>)
}

export default AnnotationTimeline;
