import ClipDto from "../../services/clip/ClipDto";
import BucketObject from "../bucketobject/BucketObject";
import Narration from "../narration/Narration";
import Tag from "../tag/Tag";
import Clip from "./Clip";
import AnnotationMapper from "../annotation/AnnotationMapper";
import NarrationMapper from "../narration/NarrationMapper";

function asClip(dto: ClipDto) {
  return {
    id: dto.id,
    creatorId: dto.creatorId,
    sourceBucketObject: dto.sourceBucketObject ? new BucketObject(dto.sourceBucketObject) : null,
    startSecond: dto.startSecond,
    endSecond: dto.endSecond,
    annotations: dto.annotations.map(a => AnnotationMapper.asAnnotation(a)),
    narrations: dto.narrations.map(n => new Narration(n)),
    title: dto.title,
    bucketObject: dto.bucketObject ? new BucketObject(dto.bucketObject) : null,
    tags: dto.tags.map(t => new Tag(t))
  } as Clip
}

function asDto(clip: Clip) {
  return {
    id: clip.id,
    creatorId: clip.creatorId,
    sourceBucketObject: clip.sourceBucketObject ? clip.sourceBucketObject.asDto() : null,
    startSecond: clip.startSecond,
    endSecond: clip.endSecond,
    annotations: clip.annotations.map(a => AnnotationMapper.asDto(a)),
    narrations: clip.narrations.map(n => NarrationMapper.asDto(n)),
    title: clip.title,
    bucketObject: clip.bucketObject ? clip.bucketObject.asDto() : null,
    tags: clip.tags.map(t => t.asDto())
  } as ClipDto
}

const ClipMapper = {
  asClip,
  asDto
}

export default ClipMapper
