import React from 'react';
import './App.less';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {UserProvider} from "./contexts/user/UserContext";
import {SecureRoute} from "./components/route/secure/SecureRoute";
import {Login} from "./views/login/Login";
import RouteConstants from "./util/RouteConstants";
import {LandingLayout} from "./views/landinglayout/LandingLayout";
import {AppLayout} from "./views/applayout/AppLayout";
import Clips from "./views/clips/Clips";
import ClipEditor from "./views/clip/ClipEditor";
import LandingPage from "./views/landingpage/LandingPage";
import {UserPreferencesProvider} from "./contexts/userpreferences/UserPreferencesContext";
import AxiosInterceptorInjector from "./components/AxiosInterceptorInjector/AxiosInterceptorInjector";
import {QueryClient, QueryClientProvider} from "react-query";
import {Register} from "./views/register/Register";
import ConfirmEmail from "./views/confirmemail/ConfirmEmail";
import Subscribe from "./views/subscribe/Subscribe";
import SubscribeSuccess from "./views/subscribe/SubscribeSuccess";
import ResetPassword from "./views/ResetPassword/ResetPassword";
import {AudioInputProvider} from "./contexts/AudioInputContext/AudioInputContext";

const queryClient = new QueryClient()

function App() {

  return (
    <Router>
      <AxiosInterceptorInjector/>
      <QueryClientProvider client={queryClient}>
        <UserProvider>
          <Switch>
            <SecureRoute path={RouteConstants.APP}>
              <UserPreferencesProvider>
                <AudioInputProvider>
                  <AppLayout>
                    <Route
                      exact
                      path={RouteConstants.CLIP}
                    >
                      <ClipEditor/>
                    </Route>
                    <Route
                      exact
                      path={RouteConstants.CLIPS}
                    >
                      <Clips/>
                    </Route>
                    <Route
                      exact
                      path={RouteConstants.CONFIRM_EMAIL}
                    >
                      <ConfirmEmail/>
                    </Route>
                    <Route
                      exact
                      path={RouteConstants.SUBSCRIBE}
                    >
                      <Subscribe/>
                    </Route>
                    <Route
                      exact
                      path={RouteConstants.SUBSCRIBE_SUCCESS}
                    >
                      <SubscribeSuccess/>
                    </Route>
                  </AppLayout>
                </AudioInputProvider>
              </UserPreferencesProvider>
            </SecureRoute>
            <Route>
              <LandingLayout>
                <Route
                  exact
                  path={RouteConstants.LANDING_PAGE}
                >
                  <LandingPage/>
                </Route>
                <Route path={RouteConstants.LOGIN}>
                  <Login/>
                </Route>
                <Route path={RouteConstants.REGISTER}>
                  <Register/>
                </Route>
                <Route path={RouteConstants.RESET_PASSWORD}>
                  <ResetPassword/>
                </Route>
              </LandingLayout>
            </Route>
          </Switch>
        </UserProvider>
      </QueryClientProvider>
    </Router>
  );
}

export default App;
