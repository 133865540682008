import React from "react";
import Icon from "@ant-design/icons";
import {ReactComponent as ZoneOutlinedSvg} from "./ZoneOutlined.svg"
import IconProps from "../IconProps";

interface Props extends IconProps {
}

function ZoneOutlined(props: React.PropsWithChildren<Props>) {

  return (<Icon
      component={ZoneOutlinedSvg} {...props}
      style={{
        ...(props.style ? props.style : {}),
      }}
    />)
}

export default ZoneOutlined;
