import React, {useRef} from "react";
import {RGBColor} from "react-color";
import {Circle} from "react-konva";
import rgbColorToCssString from "../../../../util/RgbColorToCssString";
import {KonvaEventObject} from "konva/lib/Node";

interface Props {
  points: number[]
  color: RGBColor
  fillColor?: RGBColor
  isSelected?: boolean
  onSelect?: () => void
  onShapeChange?: (points: number[]) => void
  scale?: number
  strokeWidth?: number
}

function WhiteboardCircle({
  points,
  color,
  fillColor,
  isSelected,
  onSelect,
  onShapeChange,
  scale,
  strokeWidth
}: React.PropsWithChildren<Props>) {

  const prevPosition = useRef<number[] | null>(null)

  return (<>
    <Circle
      onClick={onSelect}
      onTap={onSelect}
      x={points[0]}
      y={points[1]}
      radius={Math.hypot(
        points[2] - points[0],
        points[3] - points[1]
      )}
      stroke={rgbColorToCssString(color)}
      tension={0.5}
      fill={fillColor && rgbColorToCssString(fillColor)}
      strokeWidth={Math.max(
        (strokeWidth || 3) * (scale || 1),
        1
      )}
      draggable
      onDragStart={(e) => {
        const mouseEvent = e.evt as MouseEvent

        prevPosition.current = [mouseEvent.offsetX, mouseEvent.offsetY]
      }}
      onDragMove={(e) => {

        const mouseEvent = e.evt as MouseEvent
        onShapeChange && prevPosition.current && onShapeChange(points.map((p, i) => {
          if (i % 2 === 0) {
            return p + mouseEvent.offsetX - (prevPosition.current ? prevPosition.current[0] : 0)
          }

          if (i % 2 === 1) {
            return p + mouseEvent.offsetY - (prevPosition.current ? prevPosition.current[1] : 0)
          }

          return p
        }))

        prevPosition.current = [mouseEvent.offsetX, mouseEvent.offsetY]
      }}
    />
    {isSelected && <>
        <Circle
            x={points[0]}
            y={points[1]}
            radius={5}
            fill="white"
            draggable
            onDragMove={(e: KonvaEventObject<DragEvent>) => onShapeChange && onShapeChange([
              e.target.x(), e.target.y(), points[2] + e.target.x() - points[0], points[3] + e.target.y() - points[1]
            ])}
        />
        <Circle
            x={points[2]}
            y={points[3]}
            radius={5}
            fill="white"
            draggable
            onDragMove={(e: KonvaEventObject<DragEvent>) => onShapeChange && onShapeChange([
              points[0], points[1], e.target.x(), e.target.y()
            ])}
        />
    </>}
  </>)
}

export default WhiteboardCircle;
