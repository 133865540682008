import React, {useRef} from "react";
import {Arrow, Circle} from "react-konva";
import {KonvaEventObject} from "konva/lib/Node";
import {RGBColor} from "react-color";
import rgbColorToCssString from "../../../../util/RgbColorToCssString";

interface Props {
  points: number[]
  color: RGBColor
  isSelected?: boolean
  onSelect?: () => void
  onLineChange?: (points: number[]) => void
  scale?: number
  tension?: number
  strokeWidth?: number
  arrowLength?: number
  arrowWidth?: number
  isDashed?: boolean
}

function WhiteboardArrow({
  points,
  color,
  isSelected,
  onSelect,
  onLineChange,
  scale,
  tension,
  strokeWidth,
  arrowLength,
  arrowWidth,
  isDashed
}: React.PropsWithChildren<Props>) {

  const prevPosition = useRef<number[] | null>(null)

  return (<>
    <Arrow
      onClick={onSelect}
      onTap={onSelect}
      points={points}
      pointerLength={(arrowLength || 10) * (scale || 1)}
      pointerWidth={(arrowWidth || 10) * (scale || 1)}
      stroke={rgbColorToCssString(color)}
      fill={rgbColorToCssString(color)}
      tension={tension || 0}
      strokeWidth={Math.max(
        (strokeWidth || 3) * (scale || 1),
        1
      )}
      draggable
      onDragStart={(e) => {
        const mouseEvent = e.evt as MouseEvent

        prevPosition.current = [mouseEvent.offsetX, mouseEvent.offsetY]
      }}
      onDragMove={(e) => {

        const mouseEvent = e.evt as MouseEvent
        onLineChange && prevPosition.current && onLineChange([
          ...points.map((p, i) => {
            if (i % 2 === 0) {
              p = p + mouseEvent.offsetX - (prevPosition.current ? prevPosition.current[0] : 0)
            } else if (i % 2 === 1) {
              p = p + mouseEvent.offsetY - (prevPosition.current ? prevPosition.current[1] : 0)
            } else {
              // shouldn't happen
            }

            return p
          })
        ])

        prevPosition.current = [mouseEvent.offsetX, mouseEvent.offsetY]
        e.target.position({
          x: 0,
          y: 0
        });
      }}
      dash={isDashed ? [10, 5] : undefined}
    />
    {isSelected && (<>
      {Array.from(Array(points.length / 2)
        .keys())
            .map(i => {
              return <Circle
                x={points[2 * i]}
                y={points[2 * i + 1]}
                radius={5}
                fill="white"
                draggable
                onDragMove={(e: KonvaEventObject<DragEvent>) => {

                  points[2 * i] = e.target.x()
                  points[2 * i + 1] = e.target.y()

                  onLineChange && onLineChange(points)
                }}
              />
            })}
    </>)}
  </>)
}

export default WhiteboardArrow;
