import React, {MutableRefObject, useCallback, useEffect, useRef, useState} from "react";
import useUserAudioStream from "./useUserAudioStream";
import S3Service from "../../../../../services/s3service/S3Service";
import Narration from "../../../../../types/narration/Narration";
import {ClipEditorAction} from "../../../../../views/clip/useClipEditor";
import fixWebmDuration from "fix-webm-duration";

export type MediaRecorderState = "inactive" | "recording" | "paused"

export default function useNarration(putObjectUrl: string, narration: Narration, audioPlayerMapRef: MutableRefObject<Map<string, MutableRefObject<HTMLAudioElement | null>>>, clipEditorDispatch: React.Dispatch<ClipEditorAction>) {

  const [audioState, setAudioState] = useState<MediaRecorderState>()
  const mediaRecorderRef = useRef< MediaRecorder>()
  const chunksRef = useRef<BlobPart[]>()
  const [recordedLength, setRecordedLength] = useState<number>(0)

  const [isReady, setIsReady] = useState<boolean>(false)

  const {stream, selectAudioInput} = useUserAudioStream()

  const audioPlayerRef = useRef<HTMLAudioElement | null>(null)

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  useEffect(() => {
    audioPlayerMapRef.current.set(narration.id, audioPlayerRef)
  }, [audioPlayerMapRef, narration.id])

  const handleStop = useCallback(async () => {
    let blob = new Blob(
      chunksRef.current,
      {type: "audio/webm"}
    )

    blob = await fixWebmDuration(blob, recordedLength * 1000, {logger: false})

    await S3Service.putBucketObject(
      putObjectUrl,
      blob,
      "audio/webm",
    )

    audioPlayerRef.current?.load()
    audioPlayerRef.current?.play()

  }, [putObjectUrl, recordedLength])

  const handleDataAvailable = (e: BlobEvent) => {
    if (chunksRef.current) {
      chunksRef.current?.push(e.data)
    }
  }

  const incrementRecordingLength = useCallback(() => {

      return setInterval(() => {
        setRecordedLength(recordedLength + 1 / 60)
      }, 1000 / 60)

    },[recordedLength, setRecordedLength]
  )

  useEffect(() => {
    if (stream && !mediaRecorderRef.current) {
      chunksRef.current = []
      mediaRecorderRef.current = new MediaRecorder(stream, {mimeType: "audio/webm; codecs=opus"})
      mediaRecorderRef.current.onstop = handleStop
      mediaRecorderRef.current.ondataavailable = handleDataAvailable
    }
  }, [handleStop, stream])

  useEffect(() => {

    let incrementInterval: NodeJS.Timeout
    if (audioState === "recording") {
      incrementInterval = incrementRecordingLength()
    }

    return () => {
      if (incrementInterval) {
        clearInterval(incrementInterval)
      }
    }
  }, [audioState, incrementRecordingLength])


  const start = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.start()
      setRecordedLength(0)
      setAudioState("recording")
    }
  }

  const pause = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.pause()
      setAudioState("paused")
    }
  }

  const stop = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current?.state !== "inactive") {
      mediaRecorderRef.current.stop()
      setAudioState("inactive")
    }
  }

  const handleIsReady = () => {
    if (audioPlayerRef.current?.duration === Infinity) {
      audioPlayerRef.current.currentTime = 100000
    } else {
      clipEditorDispatch({
        type: "UPDATE_NARRATION_TIME_RANGE",
        narration: {
          ...narration,
          endSecond: narration.startSecond + (audioPlayerRef.current?.duration || 0)
        }
      })
      setIsReady(true)
    }
  }

  const handleDurationChange = () => {


  }

  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  return {
    start,
    stop,
    pause,
    audioState,
    selectAudioInput,
    handleIsReady,
    handleDurationChange,
    isReady,
    audioPlayerRef,
    recordedLength,
    isMenuOpen,
    handleMenuClick
  }
}
