import React, {PropsWithChildren} from "react";
import TimelineFrame from "./TimelineFrame";
import Annotation from "../../../types/annotation/Annotation";

export interface Segment {
  start: number
  end: number
  playerStart: number
  playerEnd: number
  layer?: Annotation
}

interface Props {
  segment: Segment
  url: string
  width: number
}

function TimelineSegment({
  segment,
  url,
  width
}: PropsWithChildren<Props>) {

  const numFrames = Math.ceil(width / 80) + 1

  const frames = numFrames > 0 ? Array(numFrames)
    .fill(
      0,
      0,
      numFrames
    )
    .map((x, i) => {
      return (<TimelineFrame
        url={url}
        startTime={segment.playerStart + 5 * i * (segment.layer ? 0 : 1)}
        layer={segment.layer}
      />)
    }) : []

  return (<div style={{display: 'flex'}}>
    <div
      style={{
        display: 'flex', ...(segment.layer ? {
          borderLeft: '2px solid #28AC8D',
          borderRight: '2px solid #28AC8D',
        } : {})
      }}
    >
      <div
        style={{
          display: "flex",
          width: 'fit-content'
        }}
      >

        <div
          style={{
            display: 'flex',
            width: segment.layer ? width - 4: width,
            height: '100%',
            overflow: 'hidden'
          }}
        >
          {frames}
        </div>
      </div>
    </div>
  </div>)
}

export default TimelineSegment;
