import React from "react";
import Icon from "@ant-design/icons";
import {ReactComponent as GrabVerticalOutlinedSvg} from "./GrabVerticalOutlined.svg"
import IconProps from "../IconProps";

interface Props extends IconProps {
}

function GrabVerticalOutlined(props: React.PropsWithChildren<Props>) {

  return (<Icon component={GrabVerticalOutlinedSvg} {...props} />)
}

export default GrabVerticalOutlined;
