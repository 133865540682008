import FilmServiceClient from "../FilmServiceClient";
import PortalSessionDto from "./PortalSessionDto";

const createPortalSession = async () => {
  let portalSessionUrl: string | null = null

  try {
    const resp = await FilmServiceClient.post<PortalSessionDto>('/portal-session')

    portalSessionUrl = resp.data.url
  } catch (e) { /* empty */ }

  return portalSessionUrl
}

const PortalSessionService = {
  createPortalSession
}

export default PortalSessionService
