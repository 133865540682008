import {Version} from "./Version";

const {version} = Version

const config = {
  FILM_SERVICE: process.env.REACT_APP_FILM_SERVICE,
  ANALYST_MONTHLY_PRICE_ID: process.env.REACT_APP_ANALYST_MONTHLY_PRICE_ID,
  ANALYST_YEARLY_PRICE_ID: process.env.REACT_APP_ANALYST_YEARLY_PRICE_ID,
  VERSION: version
}

export default config;
