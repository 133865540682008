import {useCallback, useContext, useEffect, useState} from "react";
import {AudioInputContext} from "../../../../../contexts/AudioInputContext/AudioInputContext";

export default function useUserAudioStream() {

  const [stream, setStream] = useState<MediaStream>()

  const {preferredDeviceId} = useContext(AudioInputContext)

  const fetchAudioStream = useCallback(async () => {

    let s

    if (preferredDeviceId) {
      s = await navigator.mediaDevices.getUserMedia({audio: {deviceId: preferredDeviceId}})
    } else {
      s = await navigator.mediaDevices.getUserMedia({audio: true})
    }

    if (s) {
      setStream(s)
    }
  }, [preferredDeviceId])

  useEffect(() => {
    fetchAudioStream()
  }, [fetchAudioStream, preferredDeviceId])

  const selectAudioInput = async (deviceId: string) => {
    const s = await navigator.mediaDevices.getUserMedia({audio: {deviceId}})

    if (s) {
      setStream(s)
    }
  }

  return {
    stream,
    selectAudioInput
  }
}
