import React from "react";
import Icon from "@ant-design/icons";
import {ReactComponent as StrokeWeightOutlinedSvg} from "./StrokeWeightOutlined.svg"
import IconProps from "../IconProps";

interface Props extends IconProps {
}

function StrokeWeightOutlined(props: React.PropsWithChildren<Props>) {

  return (<Icon component={StrokeWeightOutlinedSvg} {...props} />)
}

export default StrokeWeightOutlined;
