import SubscriptionDto from "../../services/subscription/SubscriptionDto";
import SubscriptionStatus from "../subscriptionstatus/SubscriptionStatus";
import Product from "../product/Product";

export default class Subscription {
  subscriptionStatus: SubscriptionStatus
  product: Product

  constructor(dto: SubscriptionDto) {
    this.subscriptionStatus = dto.subscriptionStatus
    this.product = dto.product
  }

  asDto() {
    return {
      subscriptionStatus: this.subscriptionStatus,
      product: this.product
    } as SubscriptionDto
  }
}
