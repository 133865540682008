import React from "react";
import {Button, Card, Result} from "antd";
import {useHistory} from "react-router-dom";
import RouteConstants from "../../util/RouteConstants";

function SubscribeSuccess() {

  const history = useHistory()

  const handleClickSkip = () => {
    history.push(RouteConstants.CLIPS)
  }

  const handleClickTutorial = () => {
    history.push(RouteConstants.TUTORIAL)
  }

  return (
    <Card>
      <Result
        status="success"
        title="Subscription Successful"
        subTitle="Thank you for joining the Film Buddy community!"
        extra={[
          <Button type="text" onClick={handleClickSkip}>Skip for now</Button>,
          <Button type="primary" onClick={handleClickTutorial}>Watch Tutorial</Button>,
        ]}
      ></Result>
    </Card>
  )
}

export default SubscribeSuccess;
