import User from "../../types/user/User";
import AuthService from "../../services/auth/AuthService";
import UserService from "../../services/user/UserService";

export const useLoginModel = (setUser: (user: User) => void) => {

  const login = async (email: string, password: string) => {
    if (!(await AuthService.login(email, password))) {
      return false;
    }

    const user = await UserService.getUserDetails()

    if(user) {
      setUser(new User(user))
    }
    return true
  }

  return {
    login
  }
}
