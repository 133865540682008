import Annotation from "./Annotation";

function timeRangeContains(annotation: Annotation, time: number) {
  return time <= annotation.endSecond && time >= annotation.startSecond
}

const AnnotationUtils = {
  timeRangeContains
}

export default AnnotationUtils
