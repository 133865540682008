const LANDING_PAGE = '/'
const LOGIN = '/login';
const REGISTER = '/register';
const RESET_PASSWORD = '/reset-password'

const APP = '/app';
const CLIP = APP + '/clip/:clipId'
const CLIPS = APP + '/clips'
const CONFIRM_EMAIL = APP + '/confirm-email'
const SUBSCRIBE = APP + '/subscribe'
const SUBSCRIBE_SUCCESS = APP + SUBSCRIBE + '/success'
const TUTORIAL = APP + '/tutorial'

const routeConstants = {
  APP,
  LANDING_PAGE,
  LOGIN,
  REGISTER,
  CLIP,
  CLIPS,
  CONFIRM_EMAIL,
  SUBSCRIBE,
  TUTORIAL,
  SUBSCRIBE_SUCCESS,
  RESET_PASSWORD
};

export default routeConstants;
