import React, {useEffect, useState} from "react";
import {Divider, Form, InputNumber, Space} from "antd";
import {Saturation} from "react-color/lib/components/common";
import tinycolor from "tinycolor2";
import {AlphaPicker, HuePicker, RGBColor} from "react-color";
import Circle from "react-color/lib/components/circle/Circle";
import UserPreferences from "../../types/userpreferences/UserPreferences";
import {useDebouncedCallback} from "use-debounce";

type HSVA = tinycolor.ColorFormats.HSVA

interface Props {
  color: RGBColor
  onColorChange: (color: RGBColor) => void
  userPreferences: UserPreferences | null
  onRecentColorsChange: (colors: RGBColor[]) => void
}

function ColorPicker({
  color,
  onColorChange,
  userPreferences,
  onRecentColorsChange
}: React.PropsWithChildren<Props>) {

  const [recentColors, setRecentColors] = useState<RGBColor[]>(userPreferences?.colorPalettes.get("RECENT")?.colors || [])

  useEffect(() => {
    const rc = userPreferences?.colorPalettes.get("RECENT")?.colors

    if (rc) {
      setRecentColors(rc)
    }
  }, [userPreferences?.colorPalettes])

  const debouncedOnRecentColorsChange = useDebouncedCallback(
    onRecentColorsChange,
    2000
  )

  const handleRecentColorChange = useDebouncedCallback(
    (c: RGBColor) => {
      const newRecentColors = [c].concat(recentColors.filter(rc => !tinycolor.equals(c, rc)).slice(0, 5) || [])

      setRecentColors(newRecentColors)
      debouncedOnRecentColorsChange(newRecentColors)
    },
    750
  )

  const handleColorChange = (c: RGBColor) => {

    handleRecentColorChange(c)
    onColorChange(c)
  }

  const colorProps = {
    hsl: tinycolor(color).toHsl(),
    hsv: tinycolor(color).toHsv()
  }

  return (
    <>
      <Space direction="vertical">
        <Space
          style={{
            height: 'fit-content'
          }}
        >
          <div style={{width: 120, height: 120, position: 'relative'}}>
            <Saturation {...colorProps}  onChange={(newColor) => handleColorChange(tinycolor(newColor as unknown as HSVA).toRgb())
            }/>
          </div>
          <Form
            labelCol={{span: 4}}
            wrapperCol={{span: 20}}
            style={{
              width: 90,
              height: 'fit-content'
            }}
            size="small"
          >
            <Form.Item
              colon={false}
              label='R'
            >
              <InputNumber style={{width: 74}} min={0} max={255} value={color.r} onChange={(value: number | null) => handleColorChange({...color, r: (value || 0)})}/>
            </Form.Item>
            <Form.Item
              colon={false}
              label='G'
            >
              <InputNumber style={{width: 74}} min={0} max={255} value={color.g} onChange={(value: number | null) => handleColorChange({...color, g: (value || 0)})}/>
            </Form.Item>
            <Form.Item
              colon={false}
              label='B'
              style={{
                margin: 0
              }}
            >
              <InputNumber style={{width: 74}} min={0} max={255} value={color.b} onChange={(value: number | null) => handleColorChange({...color, b: (value || 0)})}/>
            </Form.Item>
          </Form>
          <Form
            labelCol={{span: 4}}
            wrapperCol={{span: 20}}
            style={{
              width: 90,
              height: 'fit-content'
            }}
            size="small"
          >
            <Form.Item
              colon={false}
              label='H'
            >
              <InputNumber style={{width: 74}} min={0} max={360} value={colorProps.hsl.h} onChange={(value: number | null) => handleColorChange(tinycolor({...colorProps.hsl, h: (value || 0)}).toRgb())}/>
            </Form.Item>
            <Form.Item
              colon={false}
              label='S'
            >
              <InputNumber style={{width: 74}} min={0} max={100} value={colorProps.hsl.s * 100} onChange={(value: number | null) => handleColorChange( tinycolor({...colorProps.hsl, s: (value || 0) / 100}).toRgb())}/>
            </Form.Item>
            <Form.Item
              colon={false}
              label='L'
              style={{
                margin: 0
              }}
            >
              <InputNumber style={{width: 74}} min={0} max={100} value={colorProps.hsl.l * 100} onChange={(value: number | null) => handleColorChange( tinycolor({...colorProps.hsl, l: (value || 0) / 100}).toRgb())}/>
            </Form.Item>
          </Form>
        </Space>
        <HuePicker
          color={color}
          onChange={e => handleColorChange(
            {...e.rgb, a: color.a}
          )}
        />
        <div>
          <AlphaPicker
            color={color}
            onChange={(color) => {
              handleColorChange(
                color.rgb
              )
            }}
          />
        </div>
      </Space>
      <Divider type="horizontal"/>
      <Circle
        width="316px"
        circleSize={36}
        circleSpacing={20}
        colors={recentColors.map(c => tinycolor(c).toString("hex8")) || []}
        onChange={(color) => handleColorChange(color.rgb)}
      ></Circle>
    </>
  )
}

export default ColorPicker;
