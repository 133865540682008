import React, {useRef, useState} from "react";
import {BugOutlined} from "@ant-design/icons";
import {Checkbox, Form, Input, Modal, Space} from "antd";
import {FormInstance} from "antd/es/form/Form";
import BugReportDto from "../../services/bugreport/BugReportDto";
import BugReportService from "../../services/bugreport/BugReportService";

const {TextArea} = Input;

function BugReport() {

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const formRef = useRef<FormInstance>(null);

  const handleOk = () => {
    formRef.current?.submit()
    setIsOpen(false)
  }

  const handleFinish = (values: unknown) => {
    BugReportService.create(values as BugReportDto)
    formRef.current?.resetFields()
  }

  return (
    <>
      <BugOutlined title="Report Bug" onClick={() =>setIsOpen(true)}></BugOutlined>
      <Modal title="Report a Bug" open={isOpen} onCancel={() => setIsOpen(false)} okText="Report" onOk={handleOk}>
        <Space direction="vertical" style={{width: "100%"}}>
          <Form
            ref={formRef}
            onFinish={handleFinish}
          >
            <Form.Item name="description" rules={[{required: true, message: "Please add a description"}]}>
              <TextArea rows={4} placeholder="Please describe the bug"></TextArea>
            </Form.Item>
            <Form.Item name="isOkToContact" valuePropName="checked" required initialValue={true}>
              <Checkbox defaultChecked>We may contact you for clarification about the bug</Checkbox>
            </Form.Item>
          </Form>
        </Space>
      </Modal>
    </>)
}

export default BugReport;
