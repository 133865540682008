import BucketObject from "../bucketobject/BucketObject";
import NarrationDto from "../../services/narration/NarrationDto";

export default class Narration {
  id: string
  startSecond: number
  endSecond: number
  audio: BucketObject

  constructor(dto: NarrationDto) {
    this.id = dto.id
    this.startSecond = dto.startSecond
    this.endSecond = dto.endSecond
    this.audio = new BucketObject(dto.audio)
  }

}
