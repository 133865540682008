import React from "react";
import {Space} from "antd";
import {secondsToTime} from "../../FilmVideoPlayer";

interface Props {
  videoLength: number
}

function TimeMarker({videoLength}: React.PropsWithChildren<Props>) {

  let seconds: number[] = []

  for (let i = 1; i < videoLength; i++) {
    seconds = [...seconds, i]
  }

  return (
    <div
      style={{
        width: "100%",
        height: "fit-content",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          overflow: "visible",
          display: "contents"
        }}
      >
        <div
          style={{
            display: "inline-flex"
          }}
        >
          <div
            style={{
              width: 80,
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <div></div>
            <FractionMarkers></FractionMarkers>
          </div>
          {
            seconds.map(s => {
              return (
                <SecondMarker second={s}></SecondMarker>
              )
            })
          }

        </div>
      </div>
    </div>
  )
}

interface SecondMarkerProps {
  second: number
}

function SecondMarker({second}: React.PropsWithChildren<SecondMarkerProps>) {

  return (
    <div
      style={{
        width: 80,
        display: "flex",
        justifyContent: "space-between"
      }}
    >
      <div
        style={{
          width: 0,
          height: "fit-content",
          overflow: "visible"
        }}
      >
        <Space
          direction="vertical"
          style={{
            width: 0,
            height: "fit-content",
            alignItems: "center",

          }}
        >
          <div
            style={{
              height: 16,
              width: 2,
              backgroundColor: "white",
            }}
          />
          <div
            style={{
              color: "white",
              fontSize: 12
            }}
          >{secondsToTime(second)}</div>
        </Space>
      </div>
      <FractionMarkers></FractionMarkers>
    </div>
  )
}

function FractionMarkers() {
  return(
    <>
      <div
        style={{
          width: 0,
          height: "fit-content",
          overflow: "visible"
        }}
      >
        <div
          style={{
            height: 4,
            width: 2,
            backgroundColor: "white",
          }}
        />
      </div>
      <div
        style={{
          width: 0,
          height: "fit-content",
          overflow: "visible"
        }}
      >
        <div
          style={{
            height: 8,
            width: 2,
            backgroundColor: "white",
          }}
        />
      </div>
      <div
        style={{
          width: 0,
          height: "fit-content",
          overflow: "visible"
        }}
      >
        <div
          style={{
            height: 4,
            width: 2,
            backgroundColor: "white",
          }}
        />
      </div>
      <div></div>
    </>
  )
}

export default TimeMarker;
