import React, {MutableRefObject} from "react";
import TimelineSlider from "../../Timeline/Slider/TimelineSlider";
import {Button, Divider, Popover} from "antd";
import {DeleteOutlined, PauseOutlined} from "@ant-design/icons";
import CircleFilled from "../../../../icons/CircleFilled/CircleFilled";
import Narration from "../../../../../types/narration/Narration";
import useNarration, {MediaRecorderState} from "./useNarration";
import StopFilled from "../../../../icons/StopFilled/StopFilled";
import {ClipEditorAction} from "../../../../../views/clip/useClipEditor";

interface Props {
  left: number | string
  width: number | string
  onMouseEnter: (handle: 'start' | 'end') => void
  onMouseLeave: (handle: 'start' | 'end') => void
  onClickDelete: () => void
  videoLength: number
  narration: Narration
  audioPlayerMapRef: MutableRefObject<Map<string, MutableRefObject<HTMLAudioElement | null>>>
  clipEditorDispatch: React.Dispatch<ClipEditorAction>
}

function NarrationSlider({left, onMouseEnter, onMouseLeave, onClickDelete, narration, videoLength, audioPlayerMapRef, clipEditorDispatch}: React.PropsWithChildren<Props>) {

  const {
    start,
    stop,
    pause,
    audioState,
    handleIsReady,
    audioPlayerRef,
    recordedLength,
    isMenuOpen,
    isReady
  } = useNarration(narration.audio.updateLink, narration, audioPlayerMapRef, clipEditorDispatch)

  return (
    <>

      <TimelineSlider
        left={left}
        width={`${ audioState === "recording" ? recordedLength / videoLength * 100 :(narration.endSecond - narration.startSecond) / videoLength * 100}%`}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        backgroundColor={audioState === "recording" ? "rgba(255,0,0, 1)" : 'rgba(0,21,41, 1)'}
        draggable
        style={{justifyContent: "flex-start"}}
        sliderStyle={{zIndex: 100}}
        className={audioState === "recording" ? "blinking" : ""}
      >
        {
          <Popover
            visible={!isReady || isMenuOpen}
            overlayInnerStyle={{
              backgroundColor: 'rgba(0,21,41, 1)',
              borderColor: 'rgba(0,21,41, 1)',
            }}
            overlayStyle={{
              borderColor: 'rgba(0,21,41,1)'
            }}
            style={{
              borderColor: 'rgba(0,21,41,1)'
            }}
            overlayClassName="narration-controls"
            content={
              <NarrationMenu
                onClickDelete={onClickDelete}
                audioState={audioState}
                start={start}
                pause={pause}
                stop={stop}
              />
            }
            placement="top"
          >
            {
              isReady &&
              <NarrationMenu
                  onClickDelete={onClickDelete}
                  audioState={audioState}
                  start={start}
                  pause={pause}
                  stop={stop}
              />
            }
          </Popover>
        }
        <audio ref={audioPlayerRef} src={narration.audio.getLink} onCanPlay={handleIsReady}></audio>
      </TimelineSlider>
      {
        false &&
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: left,
              width: `${recordedLength / videoLength * 100}%`,
              height: '100%',
              backgroundColor: 'rgba(255,0,0, 1)',
              borderRadius: 8,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              zIndex: 25,
            }}
            className="blinking"
          ></div>
      }
    </>
  )
}

interface MenuProps {
  onClickDelete: () => void
  start: () => void
  audioState?: MediaRecorderState
  pause: () => void
  stop: () => void
}

function NarrationMenu({onClickDelete, start, audioState, pause, stop}: React.PropsWithChildren<MenuProps>) {
  return (

    <div style={{display: "flex", alignItems: "center", zIndex: 100}}>
      <Button
        icon={<DeleteOutlined/>}
        type="text"
        style={{
          color: 'white'
        }}
        onClick={onClickDelete}
      />
      <Divider type="vertical"></Divider>
      <Button
        icon={<CircleFilled
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        />}
        type="text"
        style={{
          color: "red"
        }}
        onClick={start}
        disabled={audioState === "paused" || audioState === "recording"}
      />
      <Button
        icon={<PauseOutlined
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        />}
        type="text"
        onClick={pause}
        disabled={audioState !== "recording"}
      />
      <Button
        icon={<StopFilled
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        />}
        type="text"
        onClick={stop}
        disabled={audioState === "inactive"}
      />
    </div>
  )
}

export default NarrationSlider;
