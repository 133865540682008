import React, {useEffect, useRef} from "react";
import {Space, Typography} from "antd";
import ReactPlayer from "react-player";

const {Paragraph, Title} = Typography

interface Props {
  selectedSection: string
}

function CreateClip({selectedSection}: React.PropsWithChildren<Props>) {

  const quickstartRef = useRef<HTMLDivElement>(null)
  const createClipRef = useRef<HTMLDivElement>(null)
  const addAnAnnotationRef = useRef<HTMLDivElement>(null)
  const drawRef = useRef<HTMLDivElement>(null)
  const downloadRef = useRef<HTMLDivElement>(null)


  useEffect(() => {

    if (selectedSection === 'quickstart' && quickstartRef.current) {
      quickstartRef.current.scrollIntoView({behavior: "smooth"})
    } else if (selectedSection === 'create-clip' && createClipRef.current) {
      createClipRef.current.scrollIntoView({behavior: "smooth"})
    } else if (selectedSection === 'add-an-annotation' && addAnAnnotationRef.current) {
      addAnAnnotationRef.current.scrollIntoView({behavior: 'smooth'})
    } else if (selectedSection === 'draw' && drawRef.current) {
      drawRef.current.scrollIntoView({behavior: "smooth"})
    } else if (selectedSection === 'download' && downloadRef.current) {
      downloadRef.current.scrollIntoView({behavior: "smooth"})
    }


  }, [selectedSection])

  return (
    <>
      <Space direction="vertical">
        <Title><div ref={quickstartRef}/>Quickstart</Title>
        <Paragraph style={{textAlign: 'justify'}}>
          This Quickstart guide will show you everything you need to use Film Buddy. If you want to become a Film Buddy
          master, check out other parts of the guide! (That shouldn't take too long either)
        </Paragraph>
        <Title level={3}><div ref={createClipRef}/>Create Clip</Title>
        <Paragraph style={{textAlign: 'justify'}}>
          To create a clip, simply click the button as shown below:
        </Paragraph>
        <ReactPlayer
          url="https://filmbuddy-guide-videos.s3.amazonaws.com/create-clip.mp4"
          height={240}
          playing
          loop
        />
        <Title level={3}><div ref={addAnAnnotationRef}/>Add an Annotation</Title>
        <Paragraph style={{textAlign: 'justify'}}>
          After uploading your video, add an annotation by clicking the Timeline, hovering over the stationary Playhead (The faded yellow line) and clicking the '+' that pops up. Example shown below:
        </Paragraph>
        <ReactPlayer
          url="https://filmbuddy-guide-videos.s3.amazonaws.com/add-an-annotation.mp4"
          height={240}
          playing
          loop
        />
        <Title level={3}><div ref={drawRef}/>Draw</Title>
        <Paragraph style={{textAlign: 'justify'}}>
          Pause the video in the annotation you want to draw, select the tool you'd like to use on the left, click to add points (for lines, click Cancel or press the Esc key to finish drawing).
        </Paragraph>
        <ReactPlayer
          url="https://filmbuddy-guide-videos.s3.amazonaws.com/draw.mp4"
          height={240}
          playing
          loop
        />
        <Title level={3}><div ref={downloadRef}/>Download</Title>
        <Paragraph style={{textAlign: 'justify'}}>
          To download the clip, click the download button in the lower right hand corner:
        </Paragraph>
        <ReactPlayer
          url="https://filmbuddy-guide-videos.s3.amazonaws.com/download.mp4"
          height={240}
          playing
          loop
        />
      </Space>
    </>
  )
}

export default CreateClip;
