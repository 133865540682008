import {
  CaretRightOutlined,
  ClearOutlined,
  ExportOutlined,
  FontSizeOutlined,
  PauseOutlined,
  RedoOutlined,
  SoundOutlined,
  StepBackwardOutlined,
  StepForwardOutlined,
  UndoOutlined,
  UploadOutlined
} from "@ant-design/icons";
import {Button, Card, Popover, Progress, Radio, Slider, Space, Tooltip, Typography, Upload} from "antd";
import React, {MutableRefObject, PropsWithChildren, useCallback, useEffect, useRef} from "react";
import {UploadFile} from "antd/lib/upload/interface";
import useVolume from "../../hooks/useVolume";
import useWhiteBoardModel from "./whiteboard/WhiteboardModel";
import WhiteboardLayer from "./whiteboard/layer/WhiteboardLayer";
import MediaSlider from "./mediaslider/MediaSlider";
import Annotation from "../../types/annotation/Annotation";
import {AnnotationShapeType} from "../../types/annotation/AnnotationShape";
import ZoneOutlined from "../icons/ZoneOutlined/ZoneOutlined";
import BlockOutlined from "../icons/BlockOutlined/BlockOutlined";
import ArrowSolidOutlined from "../icons/ArrowSolidOutlined/ArrowSolidOutlined";
import CircleOutlined from "../icons/CircleOutlined/CircleOutlined";
import LineVerticalOutlined from "../icons/LineVerticalOutlined/LineVerticalOutlined";
import Narration from "../../types/narration/Narration";
import AnnotationUtils from "../../types/annotation/AnnotationUtils";
import {ClipEditorAction} from "../../views/clip/useClipEditor";
import useMultiTrackMedia from "./useMultiTrackMedia/useMultiTrackMedia";

const {Text} = Typography;

interface Props {
  url?: string;
  onClickUpload?: (file: UploadFile) => void;
  onClickNext?: () => void;
  onClickPrevious?: () => void;
  hasTrimRange?: boolean;
  trimRange: number[] | null
  getAnnotationFilePathRef?: MutableRefObject<(((id: string) => string) | undefined)>;
  onClickCreateClip?: () => void;
  isUploading?: boolean
  annotations: Annotation[]
  narrations: Narration[]
  isLoadingClip: boolean
  uploadPercent: number
  clipDispatch: React.Dispatch<ClipEditorAction>
  clipId: string
}

export function secondsToTime(seconds: number) {
  return `${Math.floor(seconds / 60)}:${Math.floor(seconds % 60)
                                            .toString()
                                            .padStart(2, '0')}`;
}
export function isInPauseRange(time: number, annotations: Annotation[]) {
  return annotations.some(layer => {
      return time > layer.startSecond && time < layer.endSecond;
    })
}

export const calculateElapsedPauseSeconds = (time: number, annotations: Annotation[]) => {
  return annotations
    .map(layer => {
      if (layer.startSecond > time) {
        return 0;
      }

      if (layer.endSecond > time) {
        return time - layer.startSecond;
      }

      return layer.endSecond - layer.startSecond;
    })
    .reduce((curr, acc) => curr + acc, 0)
}

function FilmVideoPlayer({
  url,
  onClickUpload,
  onClickNext,
  onClickPrevious,
  getAnnotationFilePathRef,
  onClickCreateClip,
  isUploading,
  annotations,
  narrations,
  trimRange,
  isLoadingClip,
  uploadPercent,
  clipDispatch,
  clipId
}: PropsWithChildren<Props>) {

  const videoPlayerRef = useRef<HTMLVideoElement>(null);

  const {
    play,
    pause,
    seekTo,
    currentTime,
    isPlaying,
    isReady,
    handleIsReady,
    duration,
    handleDurationChange,
    handleIsDraggingChange,
    handleLoadedMetadata,
    audioPlayerMapRef,
    whiteboardSize
  } = useMultiTrackMedia(
    annotations,
    narrations,
    videoPlayerRef,
    trimRange
  )

  const {
    tool,
    handleShapesChange,
    handleToolChange,
    stageMapRef,
    handleClickClear,
  } = useWhiteBoardModel(
    currentTime,
    annotations,
    clipDispatch,
    getAnnotationFilePathRef,
    (videoPlayerRef.current?.videoWidth || 1280) / whiteboardSize.width
  )

  const {
    volume,
    handleVolumeSliderChange,
    handleMuteClicked,
  } = useVolume();

  const handleSliderChange = (newValue: number) => {

    seekTo(newValue)
  }

  const handleUndo = useCallback(() => {
    clipDispatch({type: "UNDO"})
  }, [clipDispatch])

  const handleRedo = useCallback(() => {
    clipDispatch({type: "REDO"})
  }, [clipDispatch])

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      const commandKey = navigator.platform.toUpperCase()
                                  .startsWith("MAC") ? e.metaKey : e.ctrlKey
      if (commandKey && !e.shiftKey && e.key === 'z') {
        handleUndo()
      } else if (commandKey && e.shiftKey && e.key === 'z') {
        handleRedo()
      }
    },
    [handleRedo, handleUndo]
  )

  useEffect(
    () => {

      document.addEventListener(
        'keydown',
        handleKeyDown
      )

      return () => {
        document.removeEventListener(
          'keydown',
          handleKeyDown
        )
      }
    },
    [handleKeyDown]
  )

  const combinedAnnotationsLength = annotations
    .map(layer => layer.endSecond - layer.startSecond)
    .reduce(
      (curr, acc) => curr + acc,
      0
    )

  useEffect(() => {
    if (duration && !trimRange) {
      clipDispatch({type: "UPDATE_TRIM_RANGE", trimRange: [0, duration + annotations
          .map(layer => layer.endSecond - layer.startSecond)
          .reduce(
            (curr, acc) => curr + acc,
            0
          )]})
    }
  }, [duration])

  return (<div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }}
  >
    <Space>
      <div>
        <Space
          direction="vertical"
          style={{alignItems: 'center'}}
        >
          <Radio.Group
            className="ant-radio-group-vertical"
            disabled={!annotations
              .map(l => AnnotationUtils.timeRangeContains(l, currentTime))
              .reduce(
                (curr, acc) => {
                  return curr || acc
                },
                false
              )}
            value={tool}
            onChange={handleToolChange}
          >
            <Radio.Button value={AnnotationShapeType.LINE}>
              <LineVerticalOutlined/>
            </Radio.Button>
            <Radio.Button value={AnnotationShapeType.BLOCK}>
              <BlockOutlined style={{fontSize: 16}}/>
            </Radio.Button>
            <Radio.Button value={AnnotationShapeType.ARROW}>
              <ArrowSolidOutlined style={{fontSize: 16}}/>
            </Radio.Button>
            <Radio.Button value={AnnotationShapeType.ZONE}>
              <ZoneOutlined style={{fontSize: 16}}/>
            </Radio.Button>
            <Radio.Button value={AnnotationShapeType.CIRCLE}>
              <CircleOutlined style={{fontSize: 16}}/>
            </Radio.Button>
            <Radio.Button value={AnnotationShapeType.TEXT}>
              <FontSizeOutlined style={{fontSize: 16}}/>
            </Radio.Button>
          </Radio.Group>
          <Button
            danger
            title="Clear"
            onClick={handleClickClear}
            icon={<ClearOutlined/>}
          />
          <Space direction="vertical">
            <Button
              title={`Undo (${navigator.platform.toUpperCase()
                                       .startsWith("MAC") ? '⌘' : "Ctrl"} + Z)`}
              icon={<UndoOutlined/>}
              onClick={handleUndo}
            />
            <Button
              title={`Redo (${navigator.platform.toUpperCase()
                                       .startsWith("MAC") ? '⌘' : "Ctrl"} + Shift + Z)`}
              icon={<RedoOutlined/>}
              onClick={handleRedo}
            />
          </Space>
        </Space>
      </div>
      <div
        className="film-video-player"
        style={{
          position: 'relative',
          height: whiteboardSize.height || 720,
          width: whiteboardSize.width || 1280,
          background: 'white',
        }}
      >
        <video ref={videoPlayerRef} src={url} onCanPlay={handleIsReady} onLoadedMetadata={handleLoadedMetadata} onDurationChange={handleDurationChange} onEnded={() => seekTo(0)} style={{width: '100%'}}></video>
        {annotations.map(layer => {
          return <WhiteboardLayer
            key={layer.id}
            layerId={layer.id}
            shapes={layer.shapes}
            onShapesChange={handleShapesChange(layer.id)}
            tool={tool}
            visible={currentTime > layer.startSecond && currentTime < layer.endSecond}
            stageMapRef={stageMapRef}
            height={whiteboardSize.height}
            width={whiteboardSize.width}
            editable
          />
        })}
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              zIndex: 15,
              margin: '10px'
            }}
          >
            <div/>
            <div>
              {onClickCreateClip && <Tooltip title="Create clip from this play">
                  <Button
                      shape="circle"
                      icon={<ExportOutlined/>}
                      onClick={onClickCreateClip}
                      size="large"
                  />
              </Tooltip>}
            </div>
          </div>
          <div/>
          <div
            style={{
              display: 'flex',
              zIndex: 15,
              justifyContent: 'flex-end',
              margin: '80px 40px'
            }}
          >
            <div/>
          </div>
        </div>
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <div/>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              {onClickUpload && !isReady && !isLoadingClip && !url && <Upload
                  beforeUpload={(file: UploadFile) => {
                    onClickUpload(file);
                    return false;
                  }}
                  showUploadList={false}
              >
                  <Button
                      icon={<UploadOutlined/>}
                      loading={isUploading}
                  >{isUploading ? 'Uploading' : 'Click to upload'}</Button>
              </Upload>}
              {!!uploadPercent && <Progress
                  percent={uploadPercent}
                  strokeColor={'#48b89a'}
                  showInfo={false}
              />}
            </div>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',

            }}
          >
            <div
              style={{
                height: '30px',
                margin: '0 10px',
                position: 'relative'
              }}
            >
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                color: 'white',
                marginLeft: 8,
                marginRight: 8,
                marginBottom: 8,
              }}
            >
              {
                duration &&
                  <div>
                    <Text style={{color: 'white'}}> {secondsToTime(currentTime)} / {secondsToTime((duration) +
                      combinedAnnotationsLength)}</Text>
                </div>
              }
              <div>
                <Space>
                  {onClickPrevious && <Button
                      type="text"
                      onClick={onClickPrevious}
                      icon={<StepBackwardOutlined
                        style={{
                          fontSize: 24,
                          color: 'white'
                        }}
                      />}
                  />}
                  {isPlaying ? <Button
                    type="text"
                    onClick={e => {

                      e.preventDefault()
                      pause()
                    }}
                    style={{zIndex: 15}}
                    icon={<PauseOutlined
                      style={{
                        fontSize: 24,
                        color: 'white'
                      }}
                    />}
                  /> : <Button
                    type="text"
                    onClick={e => {
                      e.preventDefault()
                      play()
                    }}
                    style={{zIndex: 15}}
                    icon={<CaretRightOutlined
                      style={{
                        fontSize: 24,
                        color: 'white'
                      }}
                    />}
                  />}
                  {onClickNext && <Button
                      type="text"
                      onClick={onClickNext}
                      icon={<StepForwardOutlined
                        style={{
                          fontSize: 24,
                          color: 'white'
                        }}
                      />}
                  />}
                </Space>
              </div>
              <div>
                <Space>
                  <Popover
                    placement="top"
                    content={<Slider
                      defaultValue={100}
                      value={volume}
                      vertical
                      style={{
                        height: 100
                      }}
                      onChange={handleVolumeSliderChange}
                    />}
                  >
                    <Button
                      type="text"
                      onClick={handleMuteClicked}
                      style={{zIndex: 10}}
                      icon={<SoundOutlined
                        style={{
                          fontSize: 24,
                          marginRight: 4,
                          color: 'white'
                        }}
                      />}
                    />
                  </Popover>
                </Space>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Space>
    <Card
      type="inner"
      style={{
        width: '100%',
      }}
      bodyStyle={{
        height: 'fit-content',
      }}

    >
      {
        duration &&
        <MediaSlider
          currentTime={currentTime}
          videoLength={duration + combinedAnnotationsLength}
          onCurrentTimeChange={handleSliderChange}
          annotations={annotations}
          narrations={narrations}
          url={url || ''}
          trimRange={trimRange || [0, duration + combinedAnnotationsLength]}
          clipEditorDispatch={clipDispatch}
          onIsDraggingChange={handleIsDraggingChange}
          clipId={clipId}
          audioPlayerMapRef={audioPlayerMapRef}
        />
      }
    </Card>
  </div>)
}

export default FilmVideoPlayer;
