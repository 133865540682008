import React from "react";
import {Button} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import TimelineSlider from "../Timeline/Slider/TimelineSlider";

interface Props {
  left: number | string
  width: number | string
  onMouseEnter: (handle: 'start' | 'end') => void
  onMouseLeave: (handle: 'start' | 'end') => void
  onClickDelete: () => void
}

function AnnotationSlider({left, width, onMouseEnter, onMouseLeave, onClickDelete}: React.PropsWithChildren<Props>) {

  return (
    <TimelineSlider
      left={left}
      width={width}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      backgroundColor={'rgba(40,172,141, 1)'}
      stretchable
    >
      <Button
        icon={<DeleteOutlined/>}
        type="text"
        style={{
          color: 'white'
        }}
        onClick={onClickDelete}
      />
    </TimelineSlider>
  )
}

export default AnnotationSlider;
