import BucketObjectDto from "../../services/bucketobject/BucketObjectDto";

export default class BucketObject {
  id: string;
  bucketName: string;
  getLink: string;
  updateLink: string;
  thumbnailGetLink: string

  constructor(dto: BucketObjectDto) {
    this.id = dto.id;
    this.bucketName = dto.bucket;
    this.getLink = dto.getLink;
    this.updateLink = dto.putLink;
    this.thumbnailGetLink = dto.thumbnailGetLink
  }

  asDto() {
    return {
      id: this.id,
      bucket: this.bucketName,
      getLink: this.getLink,
      putLink: this.updateLink,
      thumbnailGetLink: this.thumbnailGetLink
    } as BucketObjectDto;
  }
}
