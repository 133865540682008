import React, {MutableRefObject} from "react";
import {Circle, Layer, Line, Stage} from "react-konva";
import useWhiteboardLayerModel from "./WhiteboardLayerModel";
import Konva from "konva";
import WhiteboardArrow from "../shapes/WhiteboardArrow";
import {Button, Card, Checkbox, Form, Popover, Slider, Space} from "antd";
import {BgColorsOutlined, CaretRightOutlined, DashOutlined, DeleteOutlined,} from "@ant-design/icons";
import WhiteboardLine from "../shapes/WhiteboardLine";
import WhiteboardBlock from "../shapes/WhiteboardBlock";
import AnnotationShape, {AnnotationShapeType} from "../../../../types/annotation/AnnotationShape";
import WhiteboardZone from "../shapes/WhiteboardZone";
import WhiteboardCircle from "../shapes/WhiteboardCircle";
import WhiteboardNewPointLine from "../shapes/WhiteboardNewPointLine";
import DotAddOutlined from "../../../icons/DotAddOutlined/DotAddOutlined";
import TensionOutlined from "../../../icons/TensionOutlined/TensionOutlined";
import StrokeWeightOutlined from "../../../icons/StrokeWeightOutlined/StrokeWeightOutlined";
import CircleFilledOutlined from "../../../icons/CircleFilledOutlined/CircleFilledOutlined";
import WhiteboardText from "../shapes/WhiteboardText";
import ColorPicker from "../../../ColorPicker/ColorPicker";

Konva.showWarnings = false

interface Props {
  tool: AnnotationShapeType;
  getImageUrlRef?: MutableRefObject<(() => string) | null>;
  layerId: string;
  shapes: AnnotationShape[];
  onShapesChange: (lines: AnnotationShape[]) => void;
  visible?: boolean;
  stageMapRef?: MutableRefObject<Map<string, React.RefObject<Konva.Stage>>>;
  height?: number;
  width?: number;
  editable?: boolean;
  style?: React.CSSProperties;
}

const WhiteboardLayer: React.FC<Props> = ({
  tool,
  getImageUrlRef,
  layerId,
  shapes,
  onShapesChange,
  visible,
  stageMapRef,
  height,
  width,
  style,
}) => {

  const {
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
    stageRef,
    stageContainerRef,
    selectedShapeId,
    setSelectedShapeId,
    handleLineChange,
    handleMouseLeave,
    handleDelete,
    eraserPosition,
    handleBlockAngleChange,
    color,
    handleColorChange,
    handleAddPointToLine,
    handleCancelAddPoint,
    isAddingPoint,
    handleTensionChange,
    handleStrokeWeightChange,
    handleArrowSizeChange,
    selectedShape,
    handleIsDashedChange,
    guideLines,
    handleFillColorChange,
    handleTextChange,
    userPreferences,
    handleDefaultStrokeWeightChange,
    handleDefaultTensionChange,
    handleUserPreferencesChange,
    handleRecentColorsChange,
    handleDefaultFontSizeChange,
    handleBackgroundColorChange
  } = useWhiteboardLayerModel(
    layerId,
    shapes,
    tool,
    onShapesChange,
    getImageUrlRef,
    stageMapRef
  );

  const notVisibleStyle = visible ? {} : {display: 'none'}

  return (<div
    ref={stageContainerRef}
    onMouseLeave={handleMouseLeave}
    style={{
      position: "absolute",
      top: 0,
      left: 0,
      height: height || 720,
      width: width || 1280, ...notVisibleStyle,
    }}
  >
    <Stage
      width={width || 1280}
      height={height || 720}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      style={{
        zIndex: 5,
        position: "absolute",
        top: 0,
        left: 0, ...style
      }}
      ref={stageRef}
    >
      <Layer>

        {guideLines.vertical.slice(1)
                   .map(v => <Line
                     points={[v, 0, v, height || 720]}
                     stroke="blue"
                     strokeWidth={1}
                     dash={[4, 10]}
                   />)}
        {guideLines.horizontal.slice(1)
                   .map(h => <Line
                     points={[0, h, width || 1280, h]}
                     stroke="blue"
                     strokeWidth={1}
                     dash={[8, 15]}
                   />)}
        <Line
          points={[guideLines.vertical[0], 0, guideLines.vertical[0], height || 720]}
          stroke="yellow"
          strokeWidth={1}
          dash={[4, 10]}
        />
        <Line
          points={[0, guideLines.horizontal[0], width || 1280, guideLines.horizontal[0]]}
          stroke="yellow"
          strokeWidth={1}
          dash={[8, 15]}
        />
        {shapes.map(shape => {

          if (shape.type === AnnotationShapeType.FREEHAND) {
            return <Line
              key={shape.id}
              points={shape.points.map(point => point * (height || 720) / 720)}
              stroke="red"
              globalCompositeOperation="source-over"
            />
          } else if (shape.type === AnnotationShapeType.LINE) {
            return <WhiteboardLine
              key={shape.id}
              points={shape.points}
              color={shape.color}
              isSelected={selectedShapeId === shape.id}
              onSelect={() => setSelectedShapeId(shape.id)}
              onLineChange={handleLineChange(shape)}
              tension={shape.tension}
              strokeWidth={shape.strokeWidth}
              isDashed={shape.isDashed}
            />
          } else if (shape.type === AnnotationShapeType.BLOCK) {
            return <WhiteboardBlock
              key={shape.id}
              points={shape.points}
              color={shape.color}
              blockAngle={shape.blockAngle || 0}
              blockLength={15}
              isSelected={selectedShapeId === shape.id}
              onSelect={() => setSelectedShapeId(shape.id)}
              onLineChange={handleLineChange(shape)}
              onBlockAngleChange={handleBlockAngleChange(shape)}
              tension={shape.tension}
              strokeWidth={shape.strokeWidth}
              isDashed={shape.isDashed}
            />
          } else if (shape.type === AnnotationShapeType.ERASER) {
            return <Line
              key={shape.id}
              points={shape.points}
              strokeWidth={20}
              stroke="red"
              lineCap="round"
              globalCompositeOperation="destination-out"
            />
          } else if (shape.type === AnnotationShapeType.ARROW) {
            return <WhiteboardArrow
              key={shape.id}
              points={shape.points}
              color={shape.color}
              isSelected={selectedShapeId === shape.id}
              onSelect={() => setSelectedShapeId(shape.id)}
              onLineChange={handleLineChange(shape)}
              tension={shape.tension}
              strokeWidth={shape.strokeWidth}
              arrowLength={shape.arrowLength}
              arrowWidth={shape.arrowWidth}
              isDashed={shape.isDashed}
            />
          } else if (shape.type === AnnotationShapeType.ZONE) {
            return <WhiteboardZone
              key={shape.id}
              points={shape.points}
              color={shape.color}
              isSelected={selectedShapeId === shape.id}
              onSelect={() => setSelectedShapeId(shape.id)}
              onShapeChange={handleLineChange(shape)}
              strokeWidth={shape.strokeWidth}
            />
          } else if (shape.type === AnnotationShapeType.CIRCLE) {
            return <WhiteboardCircle
              key={shape.id}
              points={shape.points}
              color={shape.color}
              fillColor={shape.fillColor}
              isSelected={selectedShapeId === shape.id}
              onSelect={() => setSelectedShapeId(shape.id)}
              onShapeChange={handleLineChange(shape)}
              strokeWidth={shape.strokeWidth}
            />
          } else if (shape.type === AnnotationShapeType.NEW_POINT_LINE) {
            return <>
              <WhiteboardNewPointLine
                key={shape.id}
                points={shape.points}
                color={shape.color}
                tension={shape.tension}
                strokeWidth={shape.strokeWidth}
              />
            </>
          } else if (shape.type === AnnotationShapeType.TEXT) {
            return <WhiteboardText
              key={shape.id}
              points={shape.points}
              color={shape.color}
              text={shape.text}
              fontSize={shape.fontSize}
              fontFamily={shape.fontFamily}
              isSelected={selectedShapeId === shape.id}
              onSelect={() => setSelectedShapeId(shape.id)}
              onShapeChange={handleLineChange(shape)}
              onTextChange={handleTextChange(shape)}
              userPreferences={userPreferences}
              onUserPreferencesChange={handleUserPreferencesChange}
              onRecentColorsChange={handleRecentColorsChange}
              onDefaultFontSizeChange={handleDefaultFontSizeChange}
              backgroundColor={shape.backgroundColor}
              onBackgroundColorChange={handleBackgroundColorChange(shape)}
            />
          } else {
            return <></>
          }
        })}
        {eraserPosition && tool === AnnotationShapeType.ERASER && (<Circle
          x={eraserPosition.x}
          y={eraserPosition.y}
          radius={10}
          fill="pink"
        />)}
      </Layer>
    </Stage>
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
      }}
    >
      {selectedShapeId && <Card
          style={{
            height: 'fit-content',
            zIndex: 15,
            marginRight: 10
          }}
          bodyStyle={{
            padding: 0,
            display: 'flex',
            flexDirection: 'column'
          }}
      >
          <Button
              icon={<DeleteOutlined/>}
              danger
              style={{border: 0}}
              onClick={handleDelete}
              title="Delete Shape"
          />
        {selectedShape &&
          (selectedShape.type === AnnotationShapeType.LINE || selectedShape.type === AnnotationShapeType.ARROW ||
            selectedShape.type === AnnotationShapeType.BLOCK) && <Popover
                visible={isAddingPoint}
                placement="left"
                content={<Button onClick={handleCancelAddPoint}>Cancel (Esc)</Button>}
            >
                <Button
                    icon={<DotAddOutlined/>}
                    style={{border: 0}}
                    onClick={handleAddPointToLine}
                    title="Add Point to Line"
                />
            </Popover>}
        {selectedShape && selectedShape.type !== AnnotationShapeType.TEXT && <Popover
            placement="right"
            content={
              <ColorPicker
                color={color}
                onColorChange={(newColor) => handleColorChange(newColor)}
                userPreferences={userPreferences}
                onRecentColorsChange={handleRecentColorsChange}
              />
            }
        >
            <Button
                icon={<BgColorsOutlined/>}
                style={{border: 0}}
                title="Change Color"
            />
        </Popover>}
        {selectedShape &&
          (selectedShape.type === AnnotationShapeType.LINE || selectedShape.type === AnnotationShapeType.ARROW ||
            selectedShape.type === AnnotationShapeType.BLOCK) && <Popover
                placement="left"
                content={
                  <Space direction="vertical">
                    <Slider
                      value={(shapes.find(s => s.id === selectedShapeId)?.tension || 0) * 100}
                      onChange={handleTensionChange}
                      min={0}
                      max={100}
                      style={{width: 100}}
                    />
                    <Checkbox
                      checked={selectedShape && selectedShape?.tension === (userPreferences?.lineTension || -1)}
                      disabled={selectedShape && selectedShape?.tension === (userPreferences?.lineTension || -1)}
                      onClick={handleDefaultTensionChange}
                    >
                      Default
                    </Checkbox>
                  </Space>}
            >
                <Button
                    icon={<TensionOutlined/>}
                    style={{border: 0}}
                    title="Change Tension"
                />
            </Popover>}
        {selectedShape?.type !== AnnotationShapeType.TEXT && <Popover
            placement="left"
            content={
              <Space direction="vertical">
                <Slider
                  value={(selectedShape?.strokeWidth || 3)}
                  onChange={handleStrokeWeightChange}
                  min={2}
                  max={40}
                  style={{width: 100}}
                />
                <Checkbox
                  checked={selectedShape && selectedShape?.strokeWidth === userPreferences?.strokeWidth}
                  disabled={selectedShape && selectedShape?.strokeWidth === userPreferences?.strokeWidth}
                  onClick={handleDefaultStrokeWeightChange}
                >
                  Default
                </Checkbox>
              </Space>
            }
        >
            <Button
                icon={<StrokeWeightOutlined/>}
                style={{border: 0}}
                title="Change Stroke Width"
            />
        </Popover>}
        {selectedShape?.type === AnnotationShapeType.CIRCLE && <Popover
            placement="left"
            content={<Checkbox
              value={selectedShape?.fillColor}
              onChange={handleFillColorChange}
            />}
        >
            <Button
                icon={<CircleFilledOutlined/>}
                style={{border: 0}}
                title="Fill Circle"
            >

            </Button>
        </Popover>}
        {shapes.find(s => s.id === selectedShapeId)?.type === AnnotationShapeType.ARROW && <Popover
            placement="right"
            content={<>
              <Form.Item
                label="Arrow Length"
                colon={false}
              >
                <Slider
                  value={(shapes.find(s => s.id === selectedShapeId)?.arrowLength || 10)}
                  onChange={(value: number) => handleArrowSizeChange(
                    value,
                    shapes.find(s => s.id === selectedShapeId)?.arrowWidth || 10
                  )}
                  min={5}
                  max={100}
                  style={{width: 100}}
                />
              </Form.Item>
              <Form.Item
                label="Arrow Width"
                colon={false}
              >
                <Slider
                  value={(shapes.find(s => s.id === selectedShapeId)?.arrowWidth || 10)}
                  onChange={(value: number) => handleArrowSizeChange(
                    shapes.find(s => s.id === selectedShapeId)?.arrowLength || 10,
                    value
                  )}
                  min={5}
                  max={100}
                  style={{width: 100}}
                />
              </Form.Item>
            </>}
        >
            <Button
                icon={<CaretRightOutlined/>}
                style={{border: 0}}
                title="Change Arrow Size"
            />
        </Popover>}
        {(selectedShape?.type === AnnotationShapeType.LINE || selectedShape?.type === AnnotationShapeType.BLOCK ||
          selectedShape?.type === AnnotationShapeType.ARROW) && <Button
            icon={<DashOutlined/>}
            onClick={handleIsDashedChange}
            style={{border: 0}}
            title="Dashed Line"
            type={selectedShape.isDashed ? "primary" : "default"}
        />}
      </Card>}
    </div>
  </div>)
}

export default WhiteboardLayer;
