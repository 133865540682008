import React from "react";
import WhiteboardEllipse from "./WhiteboardEllipse";
import {RGBColor} from "react-color";
import {Circle, Line} from "react-konva";
import rgbColorToCssString from "../../../../util/RgbColorToCssString";
import {KonvaEventObject} from "konva/lib/Node";

const {
  sin,
  cos
} = Math

interface Props {
  points: number[] // Should be 2 numbers for the end of the line out of the eclipse then 8 numbers on the axes of the
                   // ellipse, one axis, then the other
  color: RGBColor
  isSelected?: boolean
  onSelect?: () => void
  onShapeChange?: (points: number[]) => void
  scale?: number
  strokeWidth?: number
}

function WhiteboardZone({
  points,
  color,
  onShapeChange,
  isSelected,
  onSelect,
  scale,
  strokeWidth
}: React.PropsWithChildren<Props>) {

  const ellipseCenter = [(points[4] + points[2]) / 2, (points[5] + points[3]) / 2]

  const alpha = Math.atan2(
    points[5] - points[3],
    points[4] - points[2]
  )

  const b = Math.hypot(
    points[4] - points[2],
    points[5] - points[3]
  ) / 2

  const a = Math.hypot(
    points[8] - points[6],
    points[9] - points[7]
  ) / 2

  const onEllipseChange = (ellipsePoints: number[]) => {
    onShapeChange && onShapeChange([points[0], points[1], ...ellipsePoints])
  }

  const theta = Math.atan2(
    ellipseCenter[1] - points[1],
    ellipseCenter[0] - points[0]
  )
  const distToEdge = a * b / Math.sqrt(b ** 2 * sin(theta - alpha) ** 2 + a ** 2 * cos(theta - alpha) ** 2)

  const lineDist = Math.hypot(
    ellipseCenter[1] - points[1],
    ellipseCenter[0] - points[0]
  )

  const intersection = [
    points[0] + cos(theta) * (lineDist - distToEdge), points[1] + sin(theta) * (lineDist - distToEdge)
  ]

  return (<>
    <Line
      stroke={rgbColorToCssString({
        ...color,
        a: 1
      })}
      fill={rgbColorToCssString(color)}
      onClick={onSelect}
      onTap={onSelect}
      points={[...intersection, points[0], points[1]]}
      strokeWidth={Math.max(
        (strokeWidth || 3) * (scale || 1),
        1
      )}
    />
    <WhiteboardEllipse
      points={points.slice(
        2,
        10
      )}
      color={color}
      onShapeChange={onEllipseChange}
      onSelect={onSelect}
      isSelected={isSelected}
      scale={scale}
      strokeWidth={Math.max(
        (strokeWidth || 3) * (scale || 1),
        1
      )}
    />
    {isSelected && <Circle
        x={points[0]}
        y={points[1]}
        radius={5}
        fill="white"
        draggable
        onDragMove={(e: KonvaEventObject<DragEvent>) => {
          onShapeChange && onShapeChange([
            e.target.x(), e.target.y(), ...points.slice(
              2,
              10
            )
          ])
        }}
    />}

  </>)
}

export default WhiteboardZone;
