import React from "react";
import WhiteboardLine from "./WhiteboardLine";
import {RGBColor} from "react-color";
import {Circle} from "react-konva";

interface Props {
  points: number[]
  color: RGBColor,
  strokeWidth?: number,
  tension?: number
}

function NewComponent({
  points,
  color,
  strokeWidth,
  tension
}: React.PropsWithChildren<Props>) {

  return (<>
      <WhiteboardLine
        points={points.slice(
          0,
          points.length - 2
        )}
        color={color}
        strokeWidth={strokeWidth}
        tension={tension}
      />
      <Circle
        x={points[points.length - 2]}
        y={points[points.length - 1]}
        radius={5}
        fill="white"
      />
    </>)
}

export default NewComponent;
