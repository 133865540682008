import React, {useContext, useState} from "react";
import {Button, Card, Form, Grid, Space, Switch} from "antd";
import SubscriptionOptions from "../../components/SubscriptionOptions/SubscriptionOptions";
import CheckoutSessionService from "../../services/checkoutsession/CheckoutSessionService";
import Config from "../../Config";
import {UserContext} from "../../contexts/user/UserContext";
import SubscriptionStatus from "../../types/subscriptionstatus/SubscriptionStatus";
import {Redirect, Route} from "react-router";
import RouteConstants from "../../util/RouteConstants";

const {useBreakpoint} = Grid

function Subscribe() {

  const screens = useBreakpoint()

  const {user} = useContext(UserContext)
  const [isAnnual, setIsAnnual] = useState<boolean>(false)

  const handleClickCompleteSubscription = async () => {
    const checkoutSession = await CheckoutSessionService.createCheckoutSession(isAnnual ? Config.ANALYST_YEARLY_PRICE_ID! : Config.ANALYST_MONTHLY_PRICE_ID!)

    if (!checkoutSession) {
      return
    }

    window.location.href = checkoutSession.url
  }

  const subscriptionStatus = user?.subscriptions.find(s => s.product === 'ANALYST')?.subscriptionStatus

  if (subscriptionStatus === SubscriptionStatus.ACTIVE || subscriptionStatus === SubscriptionStatus.TRIALING) {
    return (
      <Route>
        <Redirect
          to={{
            pathname: RouteConstants.SUBSCRIBE_SUCCESS,
          }}
        />
      </Route>
    )
  }

  return (
    <Card title="Subscribe" style={{minWidth: screens.sm ? 1100 : 450}}>
      <Space direction="vertical" style={{width: '100%'}} size="large">
        <Form
          labelCol={{span: 4}}
          wrapperCol={{span: 20}}
        >
          <Form.Item label="Subscription">
            <SubscriptionOptions selectedSubscription="analyst" annual={isAnnual}/>
          </Form.Item>
          <Form.Item label="Annual Membership">
            <Switch checked={isAnnual} onChange={() => setIsAnnual(!isAnnual)}></Switch>
          </Form.Item>
          <Form.Item wrapperCol={{offset: 4}}>
            <Button type="primary" onClick={handleClickCompleteSubscription}>Start 14-day Free Trial</Button>
          </Form.Item>
        </Form>
      </Space>
    </Card>
  )
}

export default Subscribe;
