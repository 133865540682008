import React, {MutableRefObject, useCallback, useEffect, useRef} from "react";
import Narration from "../../../../types/narration/Narration";
import {AudioOutlined, PlusOutlined} from "@ant-design/icons";
import {Button, message} from "antd";
import NarrationSlider from "./Slider/NarrationSlider";
import {ClipEditorAction} from "../../../../views/clip/useClipEditor";
import NarrationService from "../../../../services/narration/NarrationService";
import NarrationMapper from "../../../../types/narration/NarrationMapper";

interface Props {
  markerPosition: number
  videoLength: number
  clipEditorDispatch: React.Dispatch<ClipEditorAction>
  onIsHoveringNarrationChange: (value: boolean) => void
  onCurrentTimeChange: (value: number) => void
  narrations: Narration[]
  clipId: string
  audioPlayerMapRef: MutableRefObject<Map<string, MutableRefObject<HTMLAudioElement | null>>>
}

function NarrationTimeline({markerPosition, videoLength, narrations, onIsHoveringNarrationChange, onCurrentTimeChange, clipEditorDispatch, clipId, audioPlayerMapRef}: React.PropsWithChildren<Props>) {

  const containerRef = useRef<HTMLDivElement>(null)
  const hoveredHandle = useRef<{annotationId: string, handle: "start" | "end"} | null>()
  const grabbedHandle = useRef<{annotationId: string, handle: "start" | "end"} | null>()
  const isMouseDown = useRef<boolean>()


  const handleMouseEnter = (annotationId: string) => (handle: "start" | "end") => {
    hoveredHandle.current = {annotationId, handle}
  }

  const handleMouseLeave = (annotationId: string) => (handle: "start" | "end") => {
    if (hoveredHandle.current?.handle === handle && hoveredHandle.current?.annotationId === annotationId) {
      hoveredHandle.current = null
    }
  }

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {

      if (!grabbedHandle.current || !containerRef.current) {
        return
      }

      const nar = narrations.find(a => a.id === grabbedHandle.current?.annotationId)

      if (!nar) {
        return
      }

      if (grabbedHandle.current?.handle === "start") {

        const value = (e.pageX - containerRef.current.getBoundingClientRect().x + containerRef.current.scrollLeft - 12) * videoLength / containerRef.current.scrollWidth
        nar.endSecond = nar.endSecond - nar.startSecond + value
        nar.startSecond = value
        onCurrentTimeChange(value)

      } else {
        // Shouldn't happen
      }
      clipEditorDispatch({type: "UPDATE_NARRATION_TIME_RANGE", narration: nar})

    }, [narrations, clipEditorDispatch, onCurrentTimeChange, videoLength]
  )

  const handleMouseDown = useCallback(
    () => {
      isMouseDown.current = true
      if (hoveredHandle.current) {
        grabbedHandle.current = hoveredHandle.current
      }
    }, [isMouseDown]
  )

  const handleMouseUp = useCallback(
    () => {
      grabbedHandle.current = null
      isMouseDown.current = false
    }, [isMouseDown]
  )

  const handleClickDelete = (narrationId: string) => () => {
    const nar = narrations.find(n => n.id === narrationId)

    if (!nar) {
      return
    }

    audioPlayerMapRef.current.delete(narrationId)
    clipEditorDispatch({ type: "REMOVE_NARRATION", narration: nar})
  }

  const handleClickAddNarration = (async() => {

    try {
      const nar = await NarrationService.createNarration({
        id: '',
        startSecond: markerPosition,
        endSecond: markerPosition,
      }, clipId)

      if (!nar) {
        message.error("There was an issue creating a narration")
        return
      }

      clipEditorDispatch({
        type: "ADD_NARRATION",
        narration: NarrationMapper.asNarration(nar)
        })
      } catch (e) {
        message.error("There was an issue creating a narration")
      }
    }
  )

  useEffect(
    () => {

      document.addEventListener(
        'mousedown',
        handleMouseDown
      )
      document.addEventListener(
        'mouseup',
        handleMouseUp
      )
      document.addEventListener(
        'mousemove',
        handleMouseMove
      )

      return () => {
        document.removeEventListener(
          'mousedown',
          handleMouseDown
        )
        document.removeEventListener(
          'mouseup',
          handleMouseUp
        )
        document.removeEventListener(
          'mousemove',
          handleMouseMove
        )
      }
    },
    [handleMouseDown, handleMouseMove, handleMouseUp]
  )

  return (
    <div
      ref={containerRef}
      onMouseOver={() => onIsHoveringNarrationChange(true)}
      onMouseOut={() => onIsHoveringNarrationChange(false)}
    style={{width: '100%', height: 'fit-content', position: "relative"}}
    >
      <div
        style={{width: '100%', backgroundColor: 'rgb(0,21,41, .4)', display: 'flex', alignItems: 'center'}}
      >
        <div
          className="unselectable"
          style={{
            width: 'fit-content',
            height: 'fit-content',
            margin: 4,
            color: 'rgba(255, 255, 255, 0.83)'
          }}
        >
          Narrations <AudioOutlined/>
        </div>
        {
          narrations.map(n => {
            return (
              <NarrationSlider
                key={n.id}
                left={`${n.startSecond / videoLength * 100}%`}
                width={`${(n.endSecond - n.startSecond) / videoLength * 100}%`}
                onMouseEnter={handleMouseEnter(n.id)}
                onMouseLeave={handleMouseLeave(n.id)}
                onClickDelete={handleClickDelete(n.id)}
                narration={n}
                audioPlayerMapRef={audioPlayerMapRef}
                videoLength={videoLength}
                clipEditorDispatch={clipEditorDispatch}
              ></NarrationSlider>
            )
          })
        }
        {
          !grabbedHandle.current &&
            <Button
                onClick={handleClickAddNarration}
                style={{
                  position: "absolute",
                  top: 0,
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  borderLeftWidth: 0,
                  left: `${markerPosition / videoLength * 100}%`,
                  height: "100%",
                  transition: "none",
                  zIndex: 25
                }}
                disabled={narrations
                  .some(narr => {
                    return markerPosition / 1000 > narr.startSecond && markerPosition / 1000 < narr.endSecond;
                  })}
                type="text"
                icon={<PlusOutlined/>}
            />
        }
      </div>
    </div>
  )
}

export default NarrationTimeline;
