import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios';
import Config from "../Config";
import RouteConstants from "../util/RouteConstants";
import {History, LocationState} from "history"
import {message} from "antd";

const axiosInstance = axios.create({
  baseURL: Config.FILM_SERVICE,
});

export const setUpInterceptors = (history: History<LocationState>) => {

  axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {

    config.headers['X-Token'] = localStorage.getItem('xsrf-token')
    return config;
  });

  axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => {

      if (response.headers['x-token']) {

        localStorage.setItem('xsrf-token', response.headers['x-token']);
      }
      return response;
    },(error: AxiosError) => {

      if (error.response?.status === 401) {
        localStorage.removeItem('xsrf-token')
        history.push(RouteConstants.LOGIN)
        message.error('There was an issue with your authentication. Please log in.', 5)
      }

      throw error.response?.data
    });
}



export default axiosInstance;
