import {Button, Col, Layout, Row, Space} from "antd";
import React, {PropsWithChildren} from "react";
import {useHistory} from "react-router-dom";
import RouteConstants from "../../util/RouteConstants";
import logo from "../../resources/logo_with_text.svg"
import logo_dark from "../../resources/logo_with_text_dark.svg"
import {Footer} from "antd/lib/layout/layout";
import config from "../../Config";

const {
  Header,
  Content
} = Layout;

export const LandingLayout: React.FC<PropsWithChildren<object>> = props => {

  const history = useHistory();

  return (<Layout className="layout">
      <Header
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '10vh'
        }}
      >
        <a href="/">
          <img
            style={{
              height: '8vh'
            }}
            src={logo}
            alt=""
          />
        </a>
        <Space>
          <Button
            type="link"
            key="1"
            onClick={() => history.push(RouteConstants.LOGIN)}
            style={{color: "white"}}
          >
            Login
          </Button>
          <Button
            type="text"
            key="2"
            onClick={() => history.push(RouteConstants.REGISTER)}
            style={{color: "white"}}
          >
            Register
          </Button>
        </Space>
      </Header>
      <Content
        style={{
          margin: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {props.children}
      </Content>
      <Footer style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
        <Row style={{width: '50%'}}>
          <Col xs={{span: 24}} sm={{span: 12}} style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'baseline'}}>
            <img
              style={{
                height: 32
              }}
              src={logo_dark}
              alt=""
            />
            <div>
              <p>© 2022 Film Buddy, LLC.</p>
              <p>v{config.VERSION}</p>
            </div>
          </Col>
        </Row>
      </Footer>
    </Layout>
  )
}
