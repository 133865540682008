import React, {useEffect, useRef} from "react";
import ReactPlayer from "react-player";
import {message} from "antd";

interface Props {
  innerRef?: React.RefObject<ReactPlayer>;
  url?: string;
  playing?: boolean;
  onDuration?: (duration: number) => void;
  onProgress?: (state: {
    played: number
    playedSeconds: number
    loaded: number
    loadedSeconds: number
  }) => void
  onSeek?: (secs: number) => void;
  onReady?: () => void;
  onError?: () => void;
  volume: number;
  height?: number;
  width?: number;
  style?: React.CSSProperties
}

function VideoPlayer({
  innerRef,
  url,
  playing,
  onDuration,
  onProgress,
  onSeek,
  onReady,
  onError,
  volume,
  height,
  width,
  style
}: Props) {

  const playerRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    if (playing) {
      playerRef.current?.play()
    }
  }, [playing])

  return (<>
      <video src={url} ref={playerRef}>

      </video>
      <ReactPlayer
        style={style || {
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        ref={innerRef}
        url={url}
        playing={playing}
        onReady={onReady}
        onDuration={onDuration}
        onError={(error: unknown) => {
          (error as Error).message && message.error((error as Error).toString())
          onError && onError()
        }}
        progressInterval={1000 / 60}
        onProgress={onProgress}
        onSeek={onSeek}
        height={height || 720}
        width={width || 1280}
        volume={volume}
      />
    </>
  )
}

export default VideoPlayer;
