function keepInRange(value: number, min: number, max: number) {
  return Math.max(
    Math.min(
      value,
      max
    ),
    min
  )
}

const MathUtils = {
  keepInRange
}

export default MathUtils
