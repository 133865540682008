import BugReportDto from "./BugReportDto";
import FilmServiceClient from "../FilmServiceClient";

const create = async (bugReport: BugReportDto) => {

  try {
    FilmServiceClient.post<boolean>('bug-report', bugReport)
  } catch (e) {
    return false
  }

  return true

}

const BugReportService = {
  create
}
export default BugReportService
