import {RGBColor} from "react-color";
import AnnotationShapeDto from "../../services/annotation/AnnotationShapeDto";

export default class AnnotationShape {
  id: string
  type: AnnotationShapeType
  points: number[]
  color: RGBColor
  blockAngle?: number
  tension?: number
  strokeWidth: number
  arrowLength?: number
  arrowWidth?: number
  isDashed?: boolean
  fillColor?: RGBColor
  text?: string
  fontSize?: number
  fontFamily?: string
  backgroundColor?: RGBColor

  constructor(dto: AnnotationShapeDto) {
    this.id = dto.id
    this.type = dto.type
    this.points = dto.points
    this.color = dto.color
    this.blockAngle = dto.blockAngle
    this.tension = dto.tension
    this.strokeWidth = dto.strokeWidth
    this.arrowLength = dto.arrowLength
    this.arrowWidth = dto.arrowWidth
    this.isDashed = dto.isDashed
    this.fillColor = dto.fillColor
    this.text = dto.text
    this.fontSize = dto.fontSize
    this.fontFamily = dto.fontFamily
  }

  asDto() {
    return {
      id: this.id,
      type: this.type,
      points: this.points,
      color: this.color,
      blockAngle: this.blockAngle,
      tension: this.tension,
      strokeWidth: this.strokeWidth,
      arrowLength: this.arrowLength,
      arrowWidth: this.arrowWidth,
      isDashed: this.isDashed,
      fillColor: this.fillColor,
      text: this.text,
      fontSize: this.fontSize,
      fontFamily: this.fontFamily
    } as AnnotationShapeDto
  }
}

export enum AnnotationShapeType {
  FREEHAND = "FREEHAND",
  LINE = "LINE",
  BLOCK = "BLOCK",
  ARROW = "ARROW",
  ERASER = "ERASER",
  ZONE = "ZONE",
  CIRCLE = "CIRCLE",
  NEW_POINT_LINE = "NEW_POINT_LINE",
  TEXT = "TEXT"
}
