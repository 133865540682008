import React from "react";
import Icon from "@ant-design/icons";
import {ReactComponent as FilmOutlinedSvg} from "./FilmOutlined.svg"
import IconProps from "../IconProps";

interface Props extends IconProps {
}

function FilmOutlined(props: React.PropsWithChildren<Props>) {

  return (<Icon component={FilmOutlinedSvg} {...props} />)
}

export default FilmOutlined;
