import * as React from "react";
import {useEffect, useReducer} from "react";
import User from "../../types/user/User";
import userService from "../../services/user/UserService";

const reducer = (user: User, newUser: User) => {
  return newUser;
};

const initialState = {
  user: null, setUser: () => {
  }
};

const localState = new User(JSON.parse(localStorage.getItem("user") || '{}'));

const UserContext = React.createContext<{ user: User | null, setUser: (user: User) => void }>(initialState);

type Props = {
  children: React.ReactNode
}

const UserProvider: React.FC<Props> = props => {
  const [user, setUser] = useReducer(reducer, localState || initialState);

  const fetchUser = async () => {
    if (!localStorage.getItem('xsrf-token')) {
      return
    }
    const userDto = await userService.getUserDetails()

    if (!userDto) {
      return
    }

    setUser(new User(userDto))
  }

  useEffect(() => {
    fetchUser()
  }, [])

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(user));
  }, [user]);

  return (
    <UserContext.Provider value={{user, setUser}}>
      {props.children}
    </UserContext.Provider>
  )
};

export {UserContext, UserProvider};
