import React from "react";
import IconProps from "../IconProps";
import {ReactComponent as StopFilledSvg} from "./StopFilled.svg"
import Icon from "@ant-design/icons";

interface Props extends IconProps {

}

function StopFilled(props: React.PropsWithChildren<Props>) {

  return (<Icon component={StopFilledSvg} {...props} />)
}

export default StopFilled;
