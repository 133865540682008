import React from "react";
import Icon from "@ant-design/icons";
import {ReactComponent as DotAddOutlinedSvg} from "./DotAddOutlined.svg"
import IconProps from "../IconProps";

interface Props extends IconProps {
}

function DotAddOutlined(props: React.PropsWithChildren<Props>) {

  return (<Icon component={DotAddOutlinedSvg} {...props} />)
}

export default DotAddOutlined;
