import React, {ReactNode, useState} from "react";
import {Menu, Modal, Space} from "antd";
import CreateClip from "./CreateClip";

type MenuItem = {key: string, title: string}
type SubMenu = {key: string, title: string, subMenuItems: MenuItem[], content: ReactNode}



interface Props {
  openKeys?: string[]
  onOpenChange?: (openKeys: React.Key[]) => void
  visible?: boolean
  onCancel?: () => void
}

function GuideModal({openKeys, onOpenChange, visible, onCancel}: React.PropsWithChildren<Props>) {

  const [selectedItem, setSelectedItem] = useState<string>('create-clip')

  const menuItems: SubMenu[] = [
    {
      key: 'quickstart',
      title: 'Quickstart',
      content: <CreateClip selectedSection={selectedItem}/>,
      subMenuItems: [
        {
          key: 'create-clip', title: 'Create Clip'
        },
        {
          key: 'add-an-annotation', title: 'Add an Annotation'
        },
        {
          key: 'draw', title: 'Draw'
        },
        {
          key: 'download', title: 'Download'
        },
      ]
    }
  ]

  return (
    <Modal title="Guide" visible={visible} onCancel={onCancel} footer={null} width={896}>
      <Space align="baseline">
        <Menu
          mode="inline"
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          onClick={e => setSelectedItem(e.key)}
          style={{width: 208}}
        >
          {
            menuItems.map(mi => {
              return (
                <Menu.SubMenu key={mi.key} title={mi.title}>
                  {
                    mi.subMenuItems.map(smi => <Menu.Item key={smi.key}>{smi.title}</Menu.Item>)
                  }
                </Menu.SubMenu>
              )
            })
          }
        </Menu>
        <div style={{height: 720, overflow: 'scroll'}}>
          {
            menuItems.find(mi => mi.subMenuItems.find(smi => smi.key === selectedItem))?.content
          }
        </div>
      </Space>
    </Modal>
  )
}

export default GuideModal;
